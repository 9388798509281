import classNames from "classnames";
import { Field, Form, Formik, ErrorMessage as FormikErrorMessage, FormikErrors, FormikProps } from "formik";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { DatePicker } from "rsuite";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { number, object, string } from "yup";

import style from "./edit-tenant.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import ErrorMessage from "components/error-message/ErrorMessage";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import QuestionMark from "components/icons/QuestionMark";
import { subpageContext } from "components/layout/ApplicationLayout";
import { SubpageLayout } from "components/layout/subpage-layout/SubpageLayout";
import {
    BUNDLES_ADD_ONS,
    CHANNEL_BUNDLES,
    CORPORATE_BUNDLES,
    CorporateBundle,
    PROCESSOR_BUNDLES,
    ProcessorBundle,
    RESTRICTED_ADD_ONS,
    SMB_BUNDLE,
} from "components/licenses/bundles";
import {
    BLANCCO_TOKEN_ID,
    computeBundleAddOnLicenses,
    computeEquivalentOfCommonLicenses,
    createLicenseTypes,
    isCommonLicense,
} from "components/licenses/common";
import {
    DeliveryForm,
    License,
    MAX_CASE_NUMBER_LENGTH,
    MAX_OPPORTUNITY_ID_LENGTH,
} from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { ErrorModal } from "components/login/ErrorModal";
import Modal from "components/modal/Modal";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import {
    DeliveryTab,
    disableBatchPoolUpdate,
    isAnyLicenseInvalid,
    populateWithLicenses,
} from "components/tenants/DeliveryTab";
import PricingTierView from "components/tenants/PricingTierView";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { generateCountries } from "domain/countries";
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from "domain/globalConstants";
import { ComputedLicenses, EmsConfiguration, LicenseData, LicenseDelivery } from "domain/licenses";
import { CombinedTier, LicensingModel, OldLicensingModelTier, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { CheckEmailAvailability } from "domain/users";
import { licensePoolService } from "services/licenses/LicensePoolService";
import {
    extractLatestLicenseRateUuid,
    fetchLicenseRateVersion,
    licenseService,
    ProductToRateList,
} from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getCurrentTenantDetails,
    getTenantName,
    getTenantTier,
    getTenantUuid,
    hasSubTenantCookie,
    hasTenantCookie,
    isUserParentInternal,
    showAllowedTierList,
} from "services/tenants/tenantCookieService";
import { EditTenantDto, tenantService } from "services/tenants/TenantService";
import { userService } from "services/user/users/UserService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import layoutStyle from "styles/layout.scss";
import {
    formatDate,
    formatDateWithoutTime,
    formatUtcDateString,
    HOUR,
    MINUTES_SECONDS_MILLISECONDS,
} from "utils/format";

import alert from "assets/images/icons/alert.svg";

import testIds from "testIds.json";

interface Props {
    customerName: string;
    status: boolean;
    tier: CombinedTier;
    expirationDate: string;
    countryCode: string;
    contactName: string;
    contactEmail: string;
    notes: string;
    uuid: string;
    salesforceAccountId: string;
    tenantRegion: string;
    parentExpirationDate: string;
    tenantType: TenantType;
    licensingModel: LicensingModel;
    inTenantAccess?: boolean;
    updateSubTenant?: {
        updateCookie: () => void;
        dropAccessHandler: () => void;
    };
    rateVersions?: ProductToRateList[];
    parentLicensingModel: LicensingModel;
    parentType: TenantType;
    parentTier: CombinedTier;
}

export interface FormValues {
    customerName: string;
    countryCode: string;
    contactName: string;
    contactEmail: string;
    notes: string;
    salesforceAccountId: string;
    status: string[];
    licensingModel: LicensingModel;
    tier: CombinedTier;
    type: TenantType;
    selectedLicenses?: License[];
    deliveryDetails: DeliveryForm;
}
export interface TenantsLicenses {
    product: string;
    remainingLicenses: number;
    available: number;
    total: number;
    expirationDate: string;
    licensePool: string;
    productId: string;
}

function createField(
    id: keyof EmsConfiguration,
    label: string,
    testId: string,
    value: string | boolean | number,
    type: "text" | "checkbox" | "number" | "label",
    inErrorState: boolean,
    handleChange?: (event: React.ChangeEvent) => void,
    errorTestId?: string
): JSX.Element {
    const className = classNames(form.input, {
        [form.fixedWidthInput]: ["text", "number"].includes(type),
        [form.inputError]: inErrorState,
    });
    const valueAttribute = type === "checkbox" ? "checked" : "value";
    const attributes = Object.assign(
        {
            id,
            name: id,
            className,
            "data-testid": testId,
            onChange: handleChange,
            type,
            [valueAttribute]: value,
        },
        type !== "number" ? {} : { min: 0 }
    );
    const element =
        type === "label" ? (
            <span className={form.fixedWidthInput} data-testid={testId}>
                {value}
            </span>
        ) : (
            <Field {...attributes} />
        );

    const labels =
        type === "checkbox" ? (
            <label className={form.container}>
                {element}
                <span className={form.checkmark} />
            </label>
        ) : (
            <>{element}</>
        );

    return (
        <div className={form.formFields}>
            <label htmlFor={id} className={classNames(style.label, { [form.inputError]: inErrorState })}>
                {label}
            </label>
            {labels}
            <div className={form.error} data-testid={errorTestId}>
                <FormikErrorMessage component="div" name={id} className={form.error} />
            </div>
        </div>
    );
}

interface Initialization {
    loading: boolean;
    error: boolean;
    ems: EmsConfiguration;
    licenses?: LicenseData[];
}

export default function EditTenantView(props: Props): JSX.Element {
    const { t } = useTranslation();
    const oldStatus = props.status;
    const [newStatus, setNewStatus] = React.useState<boolean>(oldStatus);
    const [datePickerStatusChanged, setDatePickerStatusChanged] = React.useState<boolean>(false);
    const [existingTier, setExistingTier] = React.useState<CombinedTier>(props.tier);
    const [existingExpirationDate, setExistingExpirationDate] = React.useState<string>(props.expirationDate);
    const [existingName, setExistingName] = React.useState<string>(props.customerName);
    const [existingContactEmail, setExistingContactEmail] = React.useState<string>(props.contactEmail);
    const [existingContactName, setExistingContactName] = React.useState<string>(props.contactName);
    const [existingCountryCode, setExistingCountryCode] = React.useState<string>(props.countryCode);
    const [existingSalesforceId, setExistingSalesforceAccountId] = React.useState<string>(props.salesforceAccountId);
    const [existingNotes, setExistingNotes] = React.useState<string>(props.notes);
    const [cleaned, setCleaned] = React.useState(false);
    const statusChanged = newStatus !== oldStatus;
    const formRef = React.useRef<FormikProps<EmsConfiguration & FormValues>>(null);
    const [rateVersions, setRateVersions] = React.useState<ProductToRateList[]>([]);
    const [tenantType, setTenantType] = React.useState<TenantType>(props.tenantType);
    const [computedAddOnLicenses, setComputedAddOnLicenses] = React.useState<ComputedLicenses[] | undefined>();
    const [evaluatedCommonLicenses, setEvaluatedCommonLicenses] = React.useState<number | undefined>();
    const [toDeliver, setToDeliver] = React.useState(false);
    const [initialization, setInitialization] = React.useState<Initialization>({
        ems: {
            emsId: "",
            hlEntitlements: false,
            slEntitlements: false,
            availableSlActivations: 0,
            usedSlActivations: 0,
            lastSynced: "",
        },
        error: false,
        loading: true,
    });

    const [notesCharactersLeft, setNotesCharactersLeft] = React.useState(NOTES_MAX_LENGTH - props.notes?.length);
    const notesChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setNotesCharactersLeft(event.target.maxLength - event.target.value.length);
    };
    const disableDateBeforePresentDay = () => {
        const disableDate = new Date();
        disableDate.setDate(disableDate.getDate() - 1);
        return disableDate;
    };
    const isDateDisabled = (date: Date) => {
        if (props.parentExpirationDate) {
            date.setHours(0, 0, 0, 0);
            return isBefore(date, disableDateBeforePresentDay()) || isAfter(date, new Date(props.parentExpirationDate));
        }
        return isBefore(date, disableDateBeforePresentDay());
    };
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    function trimAbortControllers() {
        abortControllers.filter((a) => !a.signal.aborted).forEach((a) => a.abort());
    }
    const handleClean = () => {
        setCleaned(true);
        if (props.parentExpirationDate) {
            setExistingExpirationDate(props.parentExpirationDate);
        } else {
            setExistingExpirationDate("");
        }
    };

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const [pricingTierModalVisible, setPricingTierModalVisible] = React.useState(false);
    const [downgradeTierModalVisible, setDowngradeTierModalVisible] = React.useState(false);
    const [validTenantTypes, setValidTenantTypes] = React.useState<TenantType[]>([props.tenantType]);
    const [licensingAlertVisibility, setLicensingAlertVisibility] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [toCreateDeliveryTab, setToCreateDeliveryTab] = React.useState<boolean>(false);
    const [errorModalVisible, setErrorModalVisible] = React.useState<boolean>(false);
    const [existingLicenses, setExistingLicenses] = React.useState<{
        parentLicenses: LicenseData[];
        tenantLicenses: TenantsLicenses[];
        defaultPoolLicenses: LicenseData[];
        loading: boolean;
    }>({ parentLicenses: [], tenantLicenses: [], loading: false, defaultPoolLicenses: [] });
    const [formSubmitDisabled, setFormSubmitDisabled] = React.useState(true);
    const [selectedLicensingModal, setSelectedLicensingModal] = React.useState(props.licensingModel);
    const initialEmsConfiguration = {
        emsId: "",
        hlEntitlements: false,
        slEntitlements: false,
        availableSlActivations: 0,
        usedSlActivations: 0,
        lastSynced: "",
    };

    const context = React.useContext(subpageContext);
    const [highLightedDeliveryTab, setHighLightedDeliveryTab] = React.useState<boolean>(false);
    const [allowedTiers, setAllowedTiers] = React.useState<CombinedTier[]>(
        generateTierOptions(props.tenantType, props.licensingModel)
    );
    const isOptionalValue = props.parentType !== "INTERNAL";

    React.useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        if (isUserParentInternal()) {
            licenseService
                .fetchEmsConfiguration(props.uuid, abortController, props.tenantRegion)
                .then((emsConfiguration: EmsConfiguration) => {
                    setInitialization({
                        ems: emsConfiguration,
                        error: false,
                        loading: false,
                    });
                })
                .catch((error) => {
                    if (error instanceof SyntaxError) {
                        setInitialization({
                            ems: initialEmsConfiguration,
                            error: false,
                            loading: false,
                        });
                    } else {
                        if (!abortController.signal.aborted) {
                            setInitialization((previous) => {
                                return {
                                    ems: previous.ems,
                                    error: true,
                                    loading: false,
                                };
                            });
                        }
                    }
                });
        } else {
            setInitialization({
                ems: initialEmsConfiguration,
                error: false,
                loading: false,
            });
        }
    }, []);

    React.useEffect(() => {
        return () => trimAbortControllers();
    }, []);

    React.useEffect(() => {
        if (existingLicenses.tenantLicenses && rateVersions) {
            const commonLicense = computeEquivalentOfCommonLicenses(existingLicenses.tenantLicenses, rateVersions[0]);

            // Compute add-on licenses
            const tierLicenses = deduceTierLicenses(existingTier, false);
            const addOnLicenses = computeBundleAddOnLicenses(existingLicenses.tenantLicenses, tierLicenses);
            let allLicenses;
            if (addOnLicenses !== undefined) {
                // Set the computed add-on licenses
                setComputedAddOnLicenses(addOnLicenses);
                // Combine add-on licenses with the common license (Blancco Token)
                allLicenses = [...addOnLicenses, { licenseId: BLANCCO_TOKEN_ID, amount: commonLicense }];
            } else {
                allLicenses = [{ licenseId: BLANCCO_TOKEN_ID, amount: commonLicense }];
            }
            // Set the evaluated common licenses
            setEvaluatedCommonLicenses(commonLicense);

            // Prefill the form with the licenses to be delivered
            if (formRef.current) {
                formRef.current.setFieldValue("licensesToBeDelivered", allLicenses);
            }
        }

        if (formRef.current?.values.tier && formRef.current.values.licensingModel) {
            updateSelectedLicenses(
                populateWithLicenses(
                    formRef.current.values.tier,
                    formRef.current.values.licensingModel,
                    existingLicenses.parentLicenses,
                    t,
                    computedAddOnLicenses,
                    evaluatedCommonLicenses
                ),
                formRef.current.values.licensingModel,
                true
            );
        }
    }, [rateVersions, existingLicenses.tenantLicenses, loading, existingTier]);

    const [deliverableLicenses, setDeliverableLicenses] = React.useState<License[]>([]);

    const checkedAccountIds = new Map<string, boolean>([["", true]]);

    function setDefaultDateValue() {
        if (props.parentExpirationDate) {
            if (!cleaned) {
                const parentDate = new Date(props.parentExpirationDate);
                if (existingExpirationDate && parentDate >= new Date(existingExpirationDate)) {
                    return new Date(existingExpirationDate.split("T")[0]);
                }
            } else {
                setExistingExpirationDate(props.parentExpirationDate);
                return new Date(props.parentExpirationDate.split("T")[0]);
            }
        } else if (!cleaned && existingExpirationDate) {
            return new Date(existingExpirationDate.split("T")[0]);
        }
        return null;
    }

    const isEmsConfigurationRequired = () => {
        if (!isUserParentInternal()) {
            return false;
        }

        if (!hasTenantCookie()) {
            return true;
        }

        if (!hasSubTenantCookie()) {
            return props.uuid === getTenantUuid();
        }

        return false;
    };

    const updatedExpirationDate = existingExpirationDate
        ? formatUtcDateString(
              formatDateWithoutTime(existingExpirationDate.toString()),
              HOUR,
              MINUTES_SECONDS_MILLISECONDS,
              MINUTES_SECONDS_MILLISECONDS
          )
        : "";

    function allowAllTierOptions() {
        return props.parentType === "INTERNAL" || props.parentType === "CHANNEL";
    }

    function generateTierOptions(tenantType: TenantType, selectedModel: LicensingModel) {
        if (selectedModel === LicensingModel.BUNDLE) {
            return [props.tier];
        }
        if (isBundleWithTokenModel(selectedModel)) {
            if (allowAllTierOptions()) {
                if (tenantType === "CORPORATE") {
                    return CORPORATE_BUNDLES;
                }
                if (tenantType === "PROCESSOR") {
                    return PROCESSOR_BUNDLES;
                }
                if (tenantType === "CHANNEL") {
                    return CHANNEL_BUNDLES;
                }
                if (tenantType === "SMB") {
                    return SMB_BUNDLE;
                }
            }
            if (props.parentTier !== existingTier) {
                return [existingTier, props.parentTier];
            }
            return [props.parentTier];
        }
        return showAllowedTierList(props.uuid, tenantType, props.parentTier, !isBundleWithTokenModel(selectedModel));
    }

    function isBundleWithTokenModel(licensingModel: LicensingModel) {
        return licensingModel === LicensingModel.BUNDLE_WITH_TOKEN;
    }

    function hideSections() {
        if (!isUserParentInternal()) {
            return true;
        }
        return (
            isBundleWithTokenModel(props.licensingModel) ||
            (!isBundleWithTokenModel(props.parentLicensingModel) && props.parentType !== "INTERNAL")
        );
    }

    function generateTenantOptions(licensingModel: LicensingModel): TenantType[] {
        if (isBundleWithTokenModel(licensingModel)) {
            if (props.parentType === "INTERNAL") {
                if (props.tenantType === "CHANNEL") {
                    return ["CHANNEL"];
                } else {
                    return ["CORPORATE", "PROCESSOR", "SMB", "CHANNEL"];
                }
            }
            if (props.parentType === "CHANNEL") {
                return ["CORPORATE", "PROCESSOR", "SMB"];
            } else {
                return [props.parentType];
            }
        }
        return [props.tenantType];
    }
    function createTenantTypeSelection(formikProps: FormikProps<FormValues>) {
        if (hideSections()) {
            return null;
        }
        const tenantToTranslationKeyMap = new Map<TenantType, string>([
            ["CHANNEL", t("Common.channel")],
            ["CORPORATE", t("Common.corporate")],
            ["PROCESSOR", t("Common.processor")],
            ["CUSTOMER", t("Common.tenant")],
            ["SMB", t("Common.smb")],
        ]);

        return (
            <div className={style.tierContainer}>
                <div className={classNames(form.formFields, style.topMarginGap)}>
                    <label htmlFor="type" className={classNames(style.label)}>
                        {t("AddCustomerForm.type")}
                    </label>
                    <select
                        id="type"
                        className={classNames(form.select, form.fixedWidthInput)}
                        data-testid={testIds.workArea.tenant.manageTenantDialog.details.typeSelect.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const selectedType = event.target.value as TenantType;
                            setTenantType(selectedType);
                            const tierOptions = generateTierOptions(selectedType, formikProps.values.licensingModel);
                            setAllowedTiers(tierOptions);
                            const preselectedTier = tierOptions[0];
                            setExistingTier(preselectedTier);
                            formikProps.setFieldValue("tier", preselectedTier);
                        }}
                        value={formikProps.values.type}
                    >
                        {validTenantTypes.map((value, index) => (
                            <option key={index} value={value}>
                                {tenantToTranslationKeyMap.get(value)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }

    async function fetchRatesFromApi() {
        const rates = await fetchLicenseRateVersion();
        setRateVersions(rates);
    }

    function createLicensingModelSection(formikProps: FormikProps<FormValues>) {
        const licensingModelToTranslationKey = new Map<string, string>([
            [LicensingModel.OLD_MODEL, t("AddCustomerForm.licensingModel.old")],
            [LicensingModel.BUNDLE, t("AddCustomerForm.licensingModel.bundles")],
            [LicensingModel.BUNDLE_WITH_TOKEN, t("AddCustomerForm.licensingModel.bundlesWithToken")],
        ]);

        if (hideSections()) {
            return null;
        }

        const licensingModelOptions: string[] = [props.licensingModel, LicensingModel.BUNDLE_WITH_TOKEN];
        return (
            <>
                <div className={style.tierContainer}>
                    <div className={classNames(form.formFields, style.topMarginGap)}>
                        <label htmlFor="licensingModel" className={classNames(style.label)}>
                            {t("AddCustomerForm.licensingModel.label")}
                        </label>

                        <select
                            id={"licensingModel"}
                            className={classNames(form.select, form.fixedWidthInput)}
                            onChange={(event) => {
                                formikProps.handleChange(event);
                                const licensingModel = event.target.value as LicensingModel;
                                setSelectedLicensingModal(licensingModel);
                                const oldModel = licensingModel === LicensingModel.OLD_MODEL;
                                const selectedTenantType = oldModel
                                    ? "CUSTOMER"
                                    : props.tenantType === "CHANNEL"
                                    ? "CHANNEL"
                                    : "CORPORATE";
                                setTenantType(selectedTenantType);
                                formikProps.setFieldValue("type", selectedTenantType);
                                const tierOptions = generateTierOptions(selectedTenantType, licensingModel);
                                setAllowedTiers(tierOptions);
                                setValidTenantTypes(generateTenantOptions(licensingModel));
                                const preselectedTier = tierOptions[0];
                                setExistingTier(preselectedTier);
                                formikProps.setFieldValue("tier", preselectedTier);
                                if (isBundleWithTokenModel(licensingModel)) {
                                    setLicensingAlertVisibility(true);
                                    fetchParentLicenses();
                                } else {
                                    setToCreateDeliveryTab(false);
                                }
                            }}
                            value={formikProps.values.licensingModel}
                            data-testid={testIds.workArea.tenant.editTenantDialog.details.licensingModelSelect.itself}
                        >
                            {licensingModelOptions.map((value, index) => (
                                <option key={index} value={value}>
                                    {licensingModelToTranslationKey.get(value)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        );
    }

    function deduceAddOnsBasedOnBundle(bundle: CombinedTier) {
        if (bundle === CorporateBundle.POWER || bundle === ProcessorBundle.PRO) {
            return BUNDLES_ADD_ONS;
        }
        return RESTRICTED_ADD_ONS;
    }

    function hasModelChanged(formModel: LicensingModel) {
        return props.licensingModel !== formModel;
    }

    function checkDowngrade(tiers: string[], selectedTier: CombinedTier) {
        return tiers.includes(props.tier) && tiers.indexOf(props.tier) > tiers.indexOf(selectedTier);
    }

    function downgradeTier(selectedTier: CombinedTier) {
        if (Object.keys(OldLicensingModelTier).includes(selectedTier)) {
            return checkDowngrade(Object.keys(OldLicensingModelTier), selectedTier);
        } else if (CORPORATE_BUNDLES.includes(selectedTier)) {
            return checkDowngrade(CORPORATE_BUNDLES, selectedTier);
        } else if (PROCESSOR_BUNDLES.includes(selectedTier)) {
            return checkDowngrade(PROCESSOR_BUNDLES, selectedTier);
        } else if (SMB_BUNDLE.includes(selectedTier)) {
            return checkDowngrade(SMB_BUNDLE, selectedTier);
        }
        return false;
    }

    function confirmDowngradingBundle(licensingModel: LicensingModel) {
        if (hasModelChanged(licensingModel)) {
            return;
        }
        if (isBundleWithTokenModel(licensingModel)) {
            fetchParentLicenses();
            setLicensingAlertVisibility(true);
        }
    }

    function populateOnBundleChange() {
        const newLicenses: License[] = [];
        const nonDiscardedLicenses = existingLicenses.defaultPoolLicenses.filter(
            (each) => !discardLicense(selectedLicensingModal, each.type)
        );
        nonDiscardedLicenses.forEach((license, index) => {
            const parentLicense = existingLicenses.parentLicenses.find((each) => each.licenseType === license.type);
            if (parentLicense) {
                newLicenses.push({
                    available: license.available,
                    expirationDate: license.expirationDate,
                    index: index,
                    licenseType: parentLicense.licenseType,
                    licensesToAdd: 0,
                    parentAvailableAmount: parentLicense.available,
                    parentLicenseExpirationDate: parentLicense.expirationDate,
                    productName: parentLicense.product,
                    totalOfLicenses: license.total,
                    assigned: license.total,
                });
            }
        });
        return newLicenses;
    }

    function createTierSection(formikProps: FormikProps<FormValues>) {
        return (
            <div className={style.tierContainer}>
                <div className={classNames(form.formFields, style.topMarginGap)}>
                    <label htmlFor="tier" className={classNames(style.label)}>
                        {isBundleWithTokenModel(formikProps.values.licensingModel)
                            ? t("AddCustomerForm.blanccoBundles")
                            : t("EditCustomerView.tier")}
                    </label>
                    <select
                        id="tier"
                        className={classNames(form.select, form.fixedWidthInput)}
                        data-testid={testIds.workArea.tenant.editTenantDialog.details.tierSelect.itself}
                        onChange={(event) => {
                            formikProps.handleChange(event);
                            const newTier: CombinedTier = event.target.value;
                            setExistingTier(newTier);
                            const toDowngrade = downgradeTier(newTier);
                            if (toDowngrade) {
                                setDowngradeTierModalVisible(true);
                            }
                            const licensingModel = formikProps.values.licensingModel;
                            if (isBundleWithTokenModel(licensingModel)) {
                                if (!hasModelChanged(licensingModel)) {
                                    fetchParentLicenses();
                                    setToCreateDeliveryTab(true);
                                    updateSelectedLicenses(populateOnBundleChange(), licensingModel, true);
                                } else {
                                    updateSelectedLicenses(
                                        populateWithLicenses(
                                            event.target.value,
                                            licensingModel,
                                            existingLicenses.parentLicenses,
                                            t,
                                            computedAddOnLicenses,
                                            evaluatedCommonLicenses
                                        ),
                                        licensingModel,
                                        true
                                    );
                                }
                            }
                        }}
                        value={formikProps.values.tier}
                    >
                        {allowedTiers.map((value, index) => (
                            <option key={index} value={value}>
                                {generateTierToTranslationMap(t).get(value)}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    className={style.tooltipPosition}
                    onClick={() => setPricingTierModalVisible(true)}
                    type="button"
                >
                    <QuestionMark color={theme.iconFillColor} />
                </button>
            </div>
        );
    }

    const generalInputLabels: (keyof FormValues)[] = [
        "contactEmail",
        "countryCode",
        "customerName",
        "contactName",
        "salesforceAccountId",
    ];
    const deliveryInputLabels: (keyof FormValues)[] = ["deliveryDetails"];
    const emsInputLabels: (keyof EmsConfiguration)[] = ["emsId"];

    const highlightTabWithError = (
        errors: FormikErrors<EmsConfiguration & FormValues>,
        inputLabels: string[],
        text: string,
        foundErrors = false,
        invalidLicenses?: boolean
    ) => {
        for (const each of Object.keys(errors)) {
            if (inputLabels.includes(each)) {
                foundErrors = true;
                break;
            }
        }
        if (invalidLicenses) {
            foundErrors = true;
        }
        return (
            <div className={style.gridColumns}>
                <div
                    className={classNames({
                        [form.inputError]: foundErrors,
                    })}
                >
                    {foundErrors ? (
                        <FailedRedNotificationIcon
                            backgroundColor={theme.errorIconColor}
                            iconColor={theme.contentBackgroundColor}
                        />
                    ) : null}
                </div>
                <div
                    className={classNames({
                        [style.tabTitle]: foundErrors,
                    })}
                >
                    {text}
                </div>
            </div>
        );
    };

    const createConfirmationDialog = (formikProps: FormikProps<FormValues>) => {
        if (!licensingAlertVisibility) {
            return null;
        }
        const uniqueProductNames = new Set(
            existingLicenses.tenantLicenses
                .filter((each) => discardLicense(formikProps.values.licensingModel, each.productId))
                .map((each) => each.product)
        );
        return (
            <Modal
                isOpen={licensingAlertVisibility}
                hideModal={() => {
                    setLicensingAlertVisibility(false);
                    setToDeliver(false);
                }}
                modalTitle={t("EditCustomerView.alertTitle")}
            >
                {existingLicenses.loading ? (
                    <div className={style.loaderInModal}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <div>
                        {uniqueProductNames?.size > 0 ? (
                            <div className={style.alertData}>
                                <TextBlock>{t("EditCustomerView.deleteOldLicensesAlert")}</TextBlock>
                                <ul>
                                    {Array.from(uniqueProductNames).map((each) => (
                                        <li key={each}>{each}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                        <ButtonContainer>
                            <Button
                                variant={"PRIMARY"}
                                type={"button"}
                                onClick={() => {
                                    setLoading(true);
                                    setLicensingAlertVisibility(false);
                                    setHighLightedDeliveryTab(true);
                                    try {
                                        fetchRatesFromApi().then(() => {
                                            formikProps.setFieldValue(
                                                "deliveryDetails.tokenRateVersion",
                                                extractLatestLicenseRateUuid(rateVersions)
                                            );
                                            setToCreateDeliveryTab(true);
                                        });
                                        fetchLicensesFromAllPools().then(() => {
                                            setLoading(false);
                                        });
                                        const licensingModel = formikProps.values.licensingModel;
                                        if (isBundleWithTokenModel(licensingModel)) {
                                            if (!hasModelChanged(licensingModel)) {
                                                updateSelectedLicenses(populateOnBundleChange(), licensingModel, true);
                                            } else {
                                                updateSelectedLicenses(
                                                    populateWithLicenses(
                                                        existingTier,
                                                        licensingModel,
                                                        existingLicenses.parentLicenses,
                                                        t,
                                                        computedAddOnLicenses,
                                                        evaluatedCommonLicenses
                                                    ),
                                                    licensingModel,
                                                    true
                                                );
                                            }
                                        }
                                    } catch (e) {
                                        setLoading(false);
                                        setErrorModalVisible(true);
                                    }
                                }}
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t("EditCustomerView.confirmChange")}
                            </Button>
                            <Button
                                variant={"SECONDARY"}
                                type={"button"}
                                onClick={() => {
                                    setLicensingAlertVisibility(false);
                                    setToDeliver(false);
                                    formikProps.setFieldValue("licensingModel", props.licensingModel);
                                    setSelectedLicensingModal(props.licensingModel);
                                    setValidTenantTypes(generateTenantOptions(props.licensingModel));
                                    formikProps.setFieldValue("type", props.tenantType);
                                    setTenantType(props.tenantType);
                                    const tierOptions = generateTierOptions(props.tenantType, props.licensingModel);
                                    setAllowedTiers(tierOptions);
                                    formikProps.setFieldValue("tier", props.tier);
                                    setExistingTier(props.tier);
                                }}
                                data-testid={testIds.common.confirmationDialog.undoButton}
                            >
                                {t("Common.cancel")}
                            </Button>
                        </ButtonContainer>
                    </div>
                )}
            </Modal>
        );
    };

    function discardLicense(licensingModel: LicensingModel, licenseType: string) {
        if (hasModelChanged(licensingModel)) {
            return true;
        }
        return (
            deduceAddOnsBasedOnBundle(props.tier).includes(licenseType) &&
            !deduceTierLicenses(existingTier, false, false)?.includes(licenseType) &&
            !isCommonLicense(licenseType)
        );
    }

    async function fetchParentLicenses() {
        const abortController = new AbortController();
        setExistingLicenses((prevState) => ({
            ...prevState,
            loading: true,
        }));
        const licensesResponse = await licenseService.fetchLicenses(abortController, "", false, props.uuid);
        const allProducts = createLicenseTypes(true);
        const ownParentLicenses: LicenseData[] = [];
        licensesResponse.parentLicenses.forEach((each) => {
            const convertedParentLicense: LicenseData = {
                isFeatureLicensePresent: false,
                type: each.type,
                product: allProducts.find((product) => product.productId === each.type)?.productName || each.type,
                license: each.type,
                total: each.assigned,
                available: each.assigned - each.used,
                licenseType: each.type,
                expirationDate: each.expiration,
            };
            ownParentLicenses.push(convertedParentLicense);
        });
        const convertedTenantsLicenses: TenantsLicenses[] = [];
        licensesResponse.licenses.forEach((each) => {
            convertedTenantsLicenses.push({
                product:
                    allProducts.find((product) => product.productId === each.type)?.productName || each.licenseType,
                remainingLicenses: each.available,
                available: each.available,
                total: each.total,
                expirationDate: each.expirationDate,
                licensePool: "",
                productId: each.type,
            });
        });
        setExistingLicenses({
            parentLicenses: ownParentLicenses,
            tenantLicenses: convertedTenantsLicenses.sort((a, b) => a.product.localeCompare(b.product)),
            loading: false,
            defaultPoolLicenses: licensesResponse.licenses,
        });
    }

    async function fetchLicensesFromAllPools() {
        if (hasTenantCookie() && !getCurrentTenantDetails().featureLicenses.includes("FEATURE_LICENSE_POOLS")) {
            return;
        }
        try {
            const allProducts = createLicenseTypes(true);
            const abortController = new AbortController();
            setExistingLicenses((prevState) => ({
                ...prevState,
                loading: true,
            }));
            const allPools = await licensePoolService.fetchLicensePoolsList(abortController, props.uuid);

            const licensesPromises = allPools.pools.map(async (pool) => {
                const poolLicenses = await licensePoolService.fetchPoolSpecificLicenses(
                    abortController,
                    props.uuid,
                    pool.poolUuid
                );

                return poolLicenses.allLicenses.map((each) => {
                    return {
                        product:
                            allProducts.find((product) => product.productId == each.licenseId)?.productName ||
                            each.licenseId,
                        remainingLicenses: each.amount,
                        available: each.amount,
                        total: each.totalOfLicenses,
                        expirationDate: each.expirationDate,
                        licensePool: pool.poolName,
                        productId: each.licenseId,
                    };
                });
            });

            const licensesNestedArray = await Promise.all(licensesPromises);
            const licensesToAdd = licensesNestedArray.flat();

            setExistingLicenses((prevState) => ({
                ...prevState,
                loading: false,
                tenantLicenses: licensesToAdd.sort((a, b) => a.product.localeCompare(b.product)),
            }));
        } catch {
            setInitialization((previous) => ({
                ...previous,
                loading: false,
                error: true,
            }));
        }
    }

    function updateSelectedLicenses(
        selectedLicenses: License[],
        licensingModel: LicensingModel,
        initialChange: boolean
    ) {
        if (isBundleWithTokenModel(licensingModel)) {
            setToDeliver(true);
        }
        if (hasModelChanged(licensingModel) || !initialChange) {
            setDeliverableLicenses(selectedLicenses);
            return;
        }
        const preselectedLicenses: License[] = [];
        const nonDiscardedLicenses = existingLicenses.defaultPoolLicenses.filter(
            (each) => !discardLicense(licensingModel, each.type)
        );

        selectedLicenses.forEach((eachSelected) => {
            const existingLicense = nonDiscardedLicenses.find((each) => each.type === eachSelected.licenseType);
            if (existingLicense) {
                preselectedLicenses.push({
                    ...eachSelected,
                    available: existingLicense.available,
                    expirationDate: existingLicense.expirationDate,
                    totalOfLicenses: existingLicense.total,
                    licensesToAdd: 0,
                });
            } else {
                preselectedLicenses.push(eachSelected);
            }
        });
        setDeliverableLicenses(preselectedLicenses);
    }

    function createDeliveryDetailsSection(formikProps: FormikProps<FormValues>) {
        return (
            <DeliveryTab
                defaultTenantLicenses={existingLicenses.parentLicenses}
                selectedLicenses={deliverableLicenses}
                allTenantLicenses={existingLicenses.tenantLicenses.filter((each) =>
                    discardLicense(formikProps.values.licensingModel, each.productId)
                )}
                tier={formikProps.values.tier}
                licensingModel={formikProps.values.licensingModel}
                setSelectedLicenses={(licenses) => {
                    updateSelectedLicenses(licenses, formikProps.values.licensingModel, false);
                    if (disableBatchPoolUpdate(licenses, evaluatedCommonLicenses, computedAddOnLicenses)) {
                        formikProps.setFieldValue("deliveryDetails.toPopulatePools", false);
                    }
                }}
                deliveryDetails={formikProps.values.deliveryDetails}
                errors={formikProps.errors}
                handleChange={formikProps.handleChange}
                inEdit={true}
                rateVersions={rateVersions}
                handleBlur={formikProps.handleBlur}
                tenantUuid={props.uuid}
                loadingLicenses={loading}
                equivalentCommonLicenses={evaluatedCommonLicenses}
                loading={loading}
                addOns={computedAddOnLicenses}
                toUpdatePools={hasModelChanged(formikProps.values.licensingModel)}
            />
        );
    }
    interface Result {
        title: string;
        message: string;
        resultVisible: boolean;
        resultLoading: boolean;
        handleCookie?: boolean;
    }

    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
        resultVisible: false,
        resultLoading: true,
        handleCookie: false,
    });

    const editTenant = (editTenant: EditTenantDto) => {
        const updateTenantCookie =
            props.inTenantAccess && (editTenant.customerName != getTenantName() || editTenant.tier != getTenantTier());
        const customerName = props.inTenantAccess ? getTenantName() : props.customerName;

        setResult({
            ...result,
            title: t("EditCustomerView.title", { customerName: props.customerName }),
            resultVisible: true,
        });

        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .editTenant(props.uuid, editTenant, abortController)
            .then(() => {
                setResult({
                    title: t("EditCustomerView.editCustomerTitle"),
                    message: t("EditCustomerView.successMessage", { customerName: customerName }),
                    resultVisible: true,
                    resultLoading: false,
                    ...(updateTenantCookie && { handleCookie: true }),
                });
            })
            .catch(() => {
                setResult({
                    title: t("EditCustomerView.editCustomerFailedTitle"),
                    message: t("EditCustomerView.failureMessage", { customerName: customerName }),
                    resultVisible: true,
                    resultLoading: false,
                });
            });
    };

    const handleResultModalClickEvent = () => {
        if (result.handleCookie) {
            props.updateSubTenant?.updateCookie();
            return;
        }

        context?.setSubpage(undefined);
    };

    return (
        <>
            <SubpageLayout
                loading={initialization.loading}
                visible={true}
                title={t("EditCustomerView.title", { customerName: props.customerName })}
                buttons={
                    <>
                        <Button
                            variant={"PRIMARY"}
                            type="submit"
                            disabled={formSubmitDisabled}
                            form="editTenantForm"
                            testId={testIds.workArea.tenant.editTenantDialog.saveButton}
                        >
                            {t("Common.save")}
                        </Button>
                        {formRef.current?.isValidating || (loading && <LoadingIndicator small={true} />)}
                    </>
                }
            >
                {initialization.error ? (
                    <ErrorMessage />
                ) : (
                    <>
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                emsId: initialization.ems.emsId,
                                hlEntitlements: initialization.ems.hlEntitlements,
                                slEntitlements: initialization.ems.slEntitlements,
                                availableSlActivations: initialization.ems.availableSlActivations,
                                usedSlActivations: initialization.ems.usedSlActivations,
                                lastSynced: initialization.ems.lastSynced,
                                customerName: props.customerName,
                                countryCode: props.countryCode,
                                contactName: props.contactName,
                                contactEmail: props.contactEmail,
                                notes: props.notes,
                                tier: props.tier,
                                salesforceAccountId: props.salesforceAccountId,
                                // Even if we don't use Formik for status, we have to define it if we wish to receive a useful
                                // "dirty" status from it. We have an HTML INPUT element for "status" and therefore Formik
                                // considers it part of the Formik form. Initially "dirty" is false, as it should. But if we
                                // don't define "status" here with the kind of value that Formik uses, "dirty" will never be
                                // false again once "status" has once been changed. That's because we haven't defined it here
                                // and therefore "status" doesn't exist. That's not the same as an empty array and that's the
                                // value that Formik uses to represent an unchecked checkbox. That is, if the checkbox was
                                // initially checked, user unchecks it, and then checks it again, Formik compares nonexistent
                                // value against ["on"]. The same applies the other way too because nonexistent will never
                                // match to an array.
                                status: props.status ? ["on"] : [],
                                licensingModel: selectedLicensingModal || props.licensingModel,
                                type: props.tenantType,
                                deliveryDetails: {
                                    deliveryType: "NEW_DEAL",
                                    caseNumber: "",
                                    opportunityId: "",
                                    notes: "",
                                    amount: null,
                                    expirationDate: "",
                                    tokenRateVersion: extractLatestLicenseRateUuid(rateVersions),
                                    toPopulatePools: false,
                                },
                            }}
                            onReset={() => context?.setSubpage(undefined)}
                            onSubmit={(formValues: EmsConfiguration & FormValues) => {
                                if (isAnyLicenseInvalid(deliverableLicenses)) {
                                    return;
                                }
                                let licenseDelivery: LicenseDelivery | null = null;
                                if (toDeliver) {
                                    const noLicenses =
                                        deliverableLicenses &&
                                        deliverableLicenses.length == 1 &&
                                        deliverableLicenses[0].licenseType == "default";
                                    licenseDelivery = {
                                        type: formValues.deliveryDetails.deliveryType,
                                        caseNumber: formValues.deliveryDetails.caseNumber,
                                        opportunityId: formValues.deliveryDetails.opportunityId,
                                        notes: formValues.deliveryDetails.notes,
                                        tokenRateVersion: formValues.deliveryDetails.tokenRateVersion,
                                        toPopulatePools: formValues.deliveryDetails.toPopulatePools,
                                        licenses: noLicenses
                                            ? []
                                            : deliverableLicenses
                                            ? deliverableLicenses.map((each) => {
                                                  return {
                                                      licenseId: each.licenseType,
                                                      expirationDate: formatUtcDateString(
                                                          formatDateWithoutTime(each.expirationDate.toString()),
                                                          23,
                                                          59,
                                                          59
                                                      ),
                                                      amount: each.licensesToAdd,
                                                      totalOfLicenses: each.totalOfLicenses,
                                                  };
                                              })
                                            : [],
                                    };
                                }

                                editTenant({
                                    licenseDelivery: licenseDelivery,
                                    licensingModel: selectedLicensingModal,
                                    type: tenantType,
                                    tenantStatus: statusChanged ? newStatus : null,
                                    emsConfiguration: {
                                        emsId: formValues.emsId,
                                        hlEntitlements: formValues.hlEntitlements,
                                        slEntitlements: formValues.slEntitlements,
                                        availableSlActivations: formValues.availableSlActivations,
                                        usedSlActivations: formValues.usedSlActivations,
                                        lastSynced: formValues.lastSynced,
                                    },
                                    expirationDate: updatedExpirationDate,
                                    customerName: existingName === props.customerName ? null : existingName,
                                    countryCode: existingCountryCode === props.countryCode ? null : existingCountryCode,
                                    contactName: existingContactName === props.contactName ? null : existingContactName,
                                    contactEmail:
                                        existingContactEmail === props.contactEmail ? null : existingContactEmail,
                                    notes: existingNotes === props.notes ? null : existingNotes,
                                    tier: existingTier,
                                    salesforceId: formValues.salesforceAccountId,
                                });
                                setDowngradeTierModalVisible(false);
                            }}
                            validateOnBlur={true}
                            validateOnChange={true}
                            validationSchema={object().shape({
                                customerName: string().required(t("EditCustomerView.nameRequired")),
                                contactEmail: (isOptionalValue
                                    ? string()
                                    : string().required(t("EditCustomerView.contactEmailRequired"))
                                )
                                    .email(t("Common.invalidEmail"))
                                    .max(EMAIL_MAX_LENGTH)
                                    .test("exists", t("Common.emailNotAvailable"), function (value: string) {
                                        // For some unknown reason React/Formik/yup passes _id_ as undefined even though the value is
                                        // initialized with an empty string in the EditTenantView form. Still, no point in making
                                        // the HTTP request in either case: null/undefined or empty.
                                        if (value == null || value == props.contactEmail) {
                                            return Promise.resolve(true);
                                        }
                                        const emailToBeValidated = value.trim();
                                        const alreadyChecked = checkedAccountIds.get(emailToBeValidated);
                                        if (alreadyChecked != null) {
                                            return Promise.resolve(alreadyChecked);
                                        }
                                        // It seems that changes in the input value are not changes in the React state and therefore
                                        // the normal useEffect cleanup doesn't take place. That's why we're manually calling
                                        // trimAbortControllers here. To cancel the previous calls.
                                        trimAbortControllers();
                                        const abortController = new AbortController();
                                        abortControllers.push(abortController);
                                        return userService
                                            .checkEmailAvailability(emailToBeValidated, abortController)
                                            .then((value: CheckEmailAvailability) => {
                                                checkedAccountIds.set(emailToBeValidated, value.emailIsAvailable);
                                                if (value.emailIsUndeliverable) {
                                                    return this.createError({
                                                        message: t("Common." + value.errorMessage, {
                                                            domain_name: value.domainName,
                                                        }),
                                                    });
                                                }
                                                return value.emailIsAvailable;
                                            });
                                    }),
                                contactName: isOptionalValue
                                    ? string()
                                    : string().required(t("EditCustomerView.contactNameRequired")),
                                emsId: string()
                                    .max(256, (params) => t("EditCustomerView.emsIdIsTooLong", { max: params.max }))
                                    .test(
                                        "exists",
                                        t("EditCustomerView.emsIdDoesNotExist"),
                                        (value: string): Promise<boolean> => {
                                            // For some unknown reason React/Formik/yup passes _id_ as undefined even though the value is
                                            // initialized with an empty string in the EditTenantView form. Still, no point in making
                                            // the HTTP request in either case: null/undefined or empty.
                                            if (value == null || value == initialization.ems.emsId) {
                                                return Promise.resolve(true);
                                            }
                                            const trimmed = value.trim();
                                            const alreadyChecked = checkedAccountIds.get(trimmed);
                                            if (alreadyChecked != null) {
                                                return Promise.resolve(alreadyChecked);
                                            }
                                            // It seems that changes in the input value are not changes in the React state and therefore
                                            // the normal useEffect cleanup doesn't take place. That's why we're manually calling
                                            // trimAbortControllers here. To cancel the previous calls.
                                            trimAbortControllers();
                                            const abortController = new AbortController();
                                            abortControllers.push(abortController);
                                            return licenseService
                                                .checkEmsCustomerIdExistence(trimmed, abortController)
                                                .then((validity: boolean) => {
                                                    checkedAccountIds.set(trimmed, validity);
                                                    return validity;
                                                });
                                        }
                                    ),
                                salesforceAccountId: isEmsConfigurationRequired()
                                    ? string().required(t("AddCustomerForm.accountIdRequired"))
                                    : string().nullable(),
                                availableSlActivations: isEmsConfigurationRequired()
                                    ? number()
                                          .min(0, t("EditCustomerView.availableSlActivationsError"))
                                          .required(t("EditCustomerView.availableSlActivationsError"))
                                    : number().min(0, t("EditCustomerView.availableSlActivationsError")),
                                usedSlActivations: number().min(0),
                                deliveryDetails: object().shape({
                                    caseNumber:
                                        props.parentType === "INTERNAL" &&
                                        deliverableLicenses.length &&
                                        deliverableLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.caseNumberRequired"))
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                            : string()
                                                  .typeError(
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  )
                                                  .max(
                                                      MAX_CASE_NUMBER_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.caseNumberLength",
                                                          {
                                                              length: MAX_CASE_NUMBER_LENGTH,
                                                          }
                                                      )
                                                  )
                                                  .matches(
                                                      /^\d+$/,
                                                      t("DeliveryHistory.addLicenseDelivery.validation.caseNumberType")
                                                  ),
                                    opportunityId:
                                        props.parentType === "INTERNAL" &&
                                        deliverableLicenses.length &&
                                        deliverableLicenses[0].licenseType != "default"
                                            ? string()
                                                  .required(t("AddCustomerForm.opportunityIdRequired"))
                                                  .max(
                                                      MAX_OPPORTUNITY_ID_LENGTH,
                                                      t(
                                                          "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                          {
                                                              length: MAX_OPPORTUNITY_ID_LENGTH,
                                                          }
                                                      )
                                                  )
                                            : string().max(
                                                  MAX_OPPORTUNITY_ID_LENGTH,
                                                  t(
                                                      "DeliveryHistory.addLicenseDelivery.validation.opportunityIdLength",
                                                      {
                                                          length: MAX_OPPORTUNITY_ID_LENGTH,
                                                      }
                                                  )
                                              ),
                                }),
                            })}
                        >
                            {(formikProps: FormikProps<EmsConfiguration & FormValues>) => {
                                const enabled =
                                    !loading &&
                                    (formikProps.dirty || datePickerStatusChanged) &&
                                    formikProps.isValid &&
                                    !isAnyLicenseInvalid(deliverableLicenses);

                                React.useEffect(() => {
                                    if (!enabled !== formSubmitDisabled) {
                                        setFormSubmitDisabled(!enabled);
                                    }
                                }, [enabled]);
                                return (
                                    <Form id="editTenantForm">
                                        <Tabs>
                                            <TabList>
                                                <Tab data-testid={testIds.workArea.tenant.editTenantDialog.details.tab}>
                                                    {highlightTabWithError(
                                                        formikProps.errors,
                                                        generalInputLabels,
                                                        t("EditCustomerView.generalDetailsTab")
                                                    )}
                                                </Tab>
                                                <Tab
                                                    data-testid={testIds.workArea.tenant.editTenantDialog.settings.tab}
                                                >
                                                    {highlightTabWithError(
                                                        formikProps.errors,
                                                        emsInputLabels,
                                                        t("Common.settings")
                                                    )}
                                                </Tab>
                                                {toCreateDeliveryTab && (
                                                    <Tab
                                                        data-testid={
                                                            testIds.workArea.tenant.manageTenantDialog.delivery.tab
                                                        }
                                                    >
                                                        {highlightTabWithError(
                                                            formikProps.errors,
                                                            deliveryInputLabels,
                                                            t("AddCustomerForm.deliveryDetails"),
                                                            highLightedDeliveryTab,
                                                            isAnyLicenseInvalid(deliverableLicenses)
                                                        )}
                                                    </Tab>
                                                )}
                                            </TabList>

                                            <TabPanel>
                                                <div>
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("EditCustomerView.customerDetails")}
                                                    </Heading>
                                                    <div className={classNames(form.formFields, style.topMarginGap)}>
                                                        <label className={classNames(style.label)}>
                                                            {t("Common.customerName")}:
                                                        </label>
                                                        <input
                                                            id="customerName"
                                                            className={classNames(form.input, form.fixedWidthInput, {
                                                                [form.inputError]: formikProps.errors.customerName,
                                                            })}
                                                            maxLength={NAME_MAX_LENGTH}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .nameInput.itself
                                                            }
                                                            onChange={(event) => {
                                                                formikProps.handleChange(event);
                                                                setExistingName(event.target.value);
                                                            }}
                                                            onBlur={formikProps.handleBlur}
                                                            value={existingName}
                                                        />
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .nameInput.errorLabel
                                                            }
                                                        >
                                                            <FormikErrorMessage name="customerName" />
                                                        </div>
                                                    </div>
                                                    {createLicensingModelSection(formikProps)}
                                                    {createTenantTypeSelection(formikProps)}
                                                    {createTierSection(formikProps)}
                                                    <div
                                                        className={classNames(
                                                            form.formFieldWithoutExtraSpacing,
                                                            style.emailAndInfoContainer
                                                        )}
                                                    >
                                                        <div className={form.formFields}>
                                                            <label className={classNames(style.label)}>
                                                                {t("EditCustomerView.contactEmail")}
                                                            </label>
                                                            <input
                                                                id="contactEmail"
                                                                className={classNames(
                                                                    form.input,
                                                                    form.fixedWidthInput,
                                                                    {
                                                                        [form.inputError]:
                                                                            formikProps.errors.contactEmail,
                                                                    }
                                                                )}
                                                                maxLength={EMAIL_MAX_LENGTH}
                                                                data-testid={
                                                                    testIds.workArea.tenant.editTenantDialog.details
                                                                        .contactEmailInput.itself
                                                                }
                                                                onChange={(event) => {
                                                                    setExistingContactEmail(event.target.value);
                                                                    formikProps.handleChange(event);
                                                                }}
                                                                onBlur={formikProps.handleBlur}
                                                                value={existingContactEmail}
                                                            />
                                                            <div
                                                                className={form.error}
                                                                data-testid={
                                                                    testIds.workArea.tenant.editTenantDialog.details
                                                                        .contactEmailInput.errorLabel
                                                                }
                                                            >
                                                                <FormikErrorMessage name="contactEmail" />
                                                            </div>
                                                        </div>
                                                        <Tooltip
                                                            content={
                                                                <div className={style.tooltipOpen}>
                                                                    {t("EditCustomerView.emailInfoToolTip")}
                                                                </div>
                                                            }
                                                            placement={"top"}
                                                        >
                                                            <div className={style.info} tabIndex={0}>
                                                                <Info
                                                                    borderColor={theme.contentBackgroundColor}
                                                                    color={theme.iconFillColor}
                                                                />
                                                            </div>
                                                        </Tooltip>
                                                    </div>

                                                    <div className={classNames(form.formFields, style.topMarginGap)}>
                                                        <label className={classNames(style.label)}>
                                                            {t("EditCustomerView.contactName")}
                                                        </label>
                                                        <input
                                                            id="contactName"
                                                            className={classNames(form.input, form.fixedWidthInput)}
                                                            maxLength={NAME_MAX_LENGTH}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .contactNameInput.itself
                                                            }
                                                            onChange={(event) => {
                                                                formikProps.handleChange(event);
                                                                setExistingContactName(event.target.value);
                                                            }}
                                                            onBlur={formikProps.handleBlur}
                                                            value={existingContactName}
                                                        />
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .contactNameInput.errorLabel
                                                            }
                                                        >
                                                            <FormikErrorMessage name="contactName" />
                                                        </div>
                                                    </div>

                                                    <div className={classNames(form.formFields, style.topMarginGap)}>
                                                        <label
                                                            htmlFor="countryCode"
                                                            className={classNames(style.label)}
                                                        >
                                                            {t("Common.country")}:
                                                        </label>
                                                        <select
                                                            id="countryCode"
                                                            className={classNames(form.select, form.fixedWidthInput)}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .countrySelect.itself
                                                            }
                                                            onChange={(event) => {
                                                                formikProps.handleChange(event);
                                                                setExistingCountryCode(event.target.value);
                                                            }}
                                                            value={existingCountryCode}
                                                        >
                                                            {generateCountries(t).map((value, index) => (
                                                                <option key={index} value={value.code}>
                                                                    {value.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                                        <div className={form.formFieldsAlignItemsTop}>
                                                            <span className={form.optional}>
                                                                {t("Common.optional")}
                                                            </span>
                                                            <label htmlFor="notes" className={classNames(style.label)}>
                                                                {t("Common.notes")}:
                                                            </label>
                                                        </div>
                                                        <div className={style.notesContainer}>
                                                            <textarea
                                                                id="notes"
                                                                className={classNames(
                                                                    form.input,
                                                                    form.fixedWidthInput,
                                                                    form.textAreaHeight
                                                                )}
                                                                maxLength={NOTES_MAX_LENGTH}
                                                                data-testid={
                                                                    testIds.workArea.tenant.editTenantDialog.details
                                                                        .noteTextArea.itself
                                                                }
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLTextAreaElement>
                                                                ) => {
                                                                    formikProps.handleChange(e);
                                                                    setExistingNotes(e.target.value);
                                                                    notesChangeHandler(e);
                                                                }}
                                                                value={existingNotes}
                                                            />
                                                            <span className={form.optional}>
                                                                {t("EditCustomerView.charactersLeft", {
                                                                    remainingCharacters: notesCharactersLeft.toString(),
                                                                    notesMaxLength: NOTES_MAX_LENGTH.toString(),
                                                                })}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {isUserParentInternal() ? (
                                                        <>
                                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                                {t("AddCustomerForm.salesforceAccount")}
                                                            </Heading>
                                                            <div className={style.regionAndInfoContainer}>
                                                                <div className={form.formFields}>
                                                                    <label
                                                                        htmlFor="salesforceAccountId"
                                                                        className={classNames(style.label)}
                                                                    >
                                                                        {t("EditCustomerView.accountId")}
                                                                    </label>
                                                                    <input
                                                                        id="salesforceAccountId"
                                                                        className={classNames(
                                                                            form.input,
                                                                            form.fixedWidthInput,
                                                                            {
                                                                                [form.inputError]:
                                                                                    formikProps.errors
                                                                                        .salesforceAccountId,
                                                                            }
                                                                        )}
                                                                        data-testid={
                                                                            testIds.workArea.tenant.editTenantDialog
                                                                                .details.salesforceAccountId.itself
                                                                        }
                                                                        onChange={(event) => {
                                                                            formikProps.handleChange(event);
                                                                            setExistingSalesforceAccountId(
                                                                                event.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={formikProps.handleBlur}
                                                                        value={existingSalesforceId}
                                                                    />
                                                                    <div
                                                                        className={form.error}
                                                                        data-testid={
                                                                            testIds.workArea.tenant.editTenantDialog
                                                                                .details.salesforceAccountId.errorLabel
                                                                        }
                                                                    >
                                                                        <FormikErrorMessage name="salesforceAccountId" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div>
                                                    {isUserParentInternal() ? (
                                                        <>
                                                            <Heading tag={"div"} variant="SUBTITLE_1">
                                                                {t("EditCustomerView.entitlementSettings")}
                                                            </Heading>
                                                            <div>
                                                                {createField(
                                                                    "emsId",
                                                                    t("EditCustomerView.accountId"),
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .accountIdInput.itself,
                                                                    formikProps.values.emsId,
                                                                    "text",
                                                                    formikProps.errors.emsId != null,
                                                                    formikProps.handleChange,
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .accountIdInput.errorLabel
                                                                )}
                                                                {createField(
                                                                    "hlEntitlements",
                                                                    t("EditCustomerView.hlEntitlements"),
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .hlEntitlementsCheckbox,
                                                                    formikProps.values.hlEntitlements,
                                                                    "checkbox",
                                                                    formikProps.errors.hlEntitlements != null,
                                                                    formikProps.handleChange
                                                                )}
                                                                {createField(
                                                                    "slEntitlements",
                                                                    t("EditCustomerView.slEntitlements"),
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .slEntitlementsCheckbox,
                                                                    formikProps.values.slEntitlements,
                                                                    "checkbox",
                                                                    formikProps.errors.slEntitlements != null,
                                                                    formikProps.handleChange
                                                                )}
                                                                {createField(
                                                                    "availableSlActivations",
                                                                    t("EditCustomerView.availableSlActivations"),
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .availableSlActivationsInput.itself,
                                                                    formikProps.values.availableSlActivations,
                                                                    "number",
                                                                    formikProps.errors.availableSlActivations != null,
                                                                    formikProps.handleChange,
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .availableSlActivationsInput.errorLabel
                                                                )}
                                                                {createField(
                                                                    "usedSlActivations",
                                                                    t("EditCustomerView.usedSlActivations"),
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .usedSlActionsLabel,
                                                                    formikProps.values.usedSlActivations,
                                                                    "label",
                                                                    false
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : null}
                                                    <Heading tag={"div"} variant="SUBTITLE_1">
                                                        {t("EditCustomerView.statusSettings")}
                                                    </Heading>
                                                    <div className={style.resultContainer}>
                                                        {t("EditCustomerView.warningOnChangeStatus")}
                                                    </div>
                                                    <div>
                                                        <span className={form.optional}>{t("Common.optional")}</span>
                                                        <label className={style.label}>
                                                            {t("EditCustomerView.status", {
                                                                customerName: props.customerName,
                                                            })}
                                                        </label>
                                                        <label className={form.container}>
                                                            <input
                                                                type="checkbox"
                                                                name="status"
                                                                checked={
                                                                    formikProps.values.status.length > 0 &&
                                                                    formikProps.values.status[0] === "on"
                                                                }
                                                                className={form.input}
                                                                onChange={(event) => {
                                                                    setNewStatus(event.target.checked);
                                                                    if (event.target.checked) {
                                                                        if (props.parentExpirationDate) {
                                                                            setExistingExpirationDate(
                                                                                props.parentExpirationDate
                                                                            );
                                                                        } else {
                                                                            setExistingExpirationDate("");
                                                                        }
                                                                    } else {
                                                                        setDatePickerStatusChanged(false);
                                                                    }
                                                                    formikProps.handleChange(event);
                                                                    usageStatisticsService.sendEvent({
                                                                        category: Category.TENANT,
                                                                        action: Action.ENABLE_TENANT,
                                                                        label: newStatus.toString(),
                                                                    });
                                                                }}
                                                                data-testid={
                                                                    testIds.workArea.tenant.editTenantDialog.settings
                                                                        .statusCheckbox
                                                                }
                                                            />
                                                            <span className={form.checkmark} />
                                                        </label>
                                                        <Tooltip
                                                            content={
                                                                <div className={style.warningMessage}>
                                                                    {t("EditCustomerView.warningTooltip")}
                                                                </div>
                                                            }
                                                            placement={"right"}
                                                        >
                                                            <div className={style.warningIcon} tabIndex={0}>
                                                                <img src={alert} alt={t("AltText.alertIcon")} />
                                                            </div>
                                                        </Tooltip>
                                                        {
                                                            <div>
                                                                <span className={form.optional}>
                                                                    {t("Common.optional")}
                                                                </span>
                                                                <label className={style.label}>
                                                                    {t("Common.accessExpiry")}:
                                                                </label>
                                                                <DatePicker
                                                                    id="expirationDate"
                                                                    placeholder={t("Common.never")}
                                                                    data-testid={
                                                                        testIds.workArea.tenant.editTenantDialog
                                                                            .settings.datePickerInput
                                                                    }
                                                                    title={t("AltText.calendar")}
                                                                    className={form.dateRange}
                                                                    placement={"top"}
                                                                    disabledDate={isDateDisabled}
                                                                    onSelect={(date) => {
                                                                        setCleaned(false);
                                                                        setDatePickerStatusChanged(true);
                                                                        setExistingExpirationDate(
                                                                            formatDate(moment(date))
                                                                        );
                                                                    }}
                                                                    ranges={[
                                                                        {
                                                                            label: "Today",
                                                                            value: new Date(),
                                                                            closeOverlay: true,
                                                                        },
                                                                    ]}
                                                                    value={setDefaultDateValue()}
                                                                    onClean={handleClean}
                                                                    renderValue={(value: Date) => {
                                                                        return existingExpirationDate == null
                                                                            ? t("Common.never")
                                                                            : `${formatDateWithoutTime(
                                                                                  value.toString()
                                                                              )}`;
                                                                    }}
                                                                    disabled={statusChanged === oldStatus}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                    {!formikProps.isValid && (
                                                        <div
                                                            className={form.error}
                                                            data-testid={
                                                                testIds.workArea.tenant.editTenantDialog.details
                                                                    .genericErrorLabel
                                                            }
                                                        >
                                                            <FormikErrorMessage
                                                                name={
                                                                    formikProps.errors.customerName
                                                                        ? "customerName"
                                                                        : formikProps.errors.contactEmail
                                                                        ? "contactEmail"
                                                                        : "salesforceAccountId"
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            {!toCreateDeliveryTab ? null : (
                                                <TabPanel onClick={() => setHighLightedDeliveryTab(false)}>
                                                    {createDeliveryDetailsSection(formikProps)}
                                                </TabPanel>
                                            )}
                                        </Tabs>
                                        <div className={classNames(style.editTenantButtons, style.okButtonContainer)}>
                                            <Modal
                                                isOpen={downgradeTierModalVisible}
                                                hideModal={() => setDowngradeTierModalVisible(false)}
                                                modalTitle={t("EditCustomerView.downgradeTierView.title")}
                                            >
                                                <FailedRedNotificationIcon
                                                    backgroundColor={theme.errorIconColor}
                                                    iconColor={theme.contentBackgroundColor}
                                                />
                                                <div className={style.resultContainer}>
                                                    {t("EditCustomerView.downgradeTierView.suggestion")}
                                                    <strong>
                                                        {t("EditCustomerView.downgradeTierView.tier", {
                                                            tier: props.tier,
                                                        })}
                                                    </strong>
                                                    {t("EditCustomerView.downgradeTierView.to")}
                                                    <strong>
                                                        {t("EditCustomerView.downgradeTierView.downgradeTier", {
                                                            downgradeTier: existingTier,
                                                        })}
                                                    </strong>
                                                    {t("EditCustomerView.downgradeTierView.confirmation")}
                                                </div>
                                                <div className={layoutStyle.buttonContainer}>
                                                    <button
                                                        className={classNames(
                                                            buttons.secondaryButton,
                                                            buttons.medium,
                                                            style.okButton
                                                        )}
                                                        onClick={() => {
                                                            setExistingTier(props.tier);
                                                            formikProps.setFieldValue("tier", props.tier);
                                                            setDowngradeTierModalVisible(false);
                                                            setLicensingAlertVisibility(false);
                                                            setToDeliver(false);
                                                        }}
                                                        data-testid={testIds.common.dialog.closeButton}
                                                    >
                                                        {t("Common.cancel")}
                                                    </button>
                                                    <button
                                                        className={classNames(
                                                            buttons.primaryButton,
                                                            buttons.medium,
                                                            buttons.deleteButton
                                                        )}
                                                        data-testid={testIds.common.confirmationDialog.confirmButton}
                                                        onClick={() => {
                                                            setDowngradeTierModalVisible(false);
                                                            confirmDowngradingBundle(formikProps.values.licensingModel);
                                                        }}
                                                    >
                                                        {t("EditCustomerView.downgradeTierView.confirmationButton")}
                                                    </button>
                                                </div>
                                            </Modal>
                                        </div>
                                        {createConfirmationDialog(formikProps)}
                                    </Form>
                                );
                            }}
                        </Formik>
                        <PricingTierView
                            tenantType={tenantType}
                            visibility={pricingTierModalVisible}
                            setVisibility={setPricingTierModalVisible}
                            selectedTier={existingTier}
                            tenantModal={selectedLicensingModal}
                        />
                    </>
                )}
            </SubpageLayout>
            <Modal isOpen={result.resultVisible} hideModal={handleResultModalClickEvent} modalTitle={result.title}>
                {result.resultLoading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <TextBlock>{result.message}</TextBlock>
                        <ButtonContainer>
                            <Button
                                variant={"PRIMARY"}
                                onClick={handleResultModalClickEvent}
                                data-testid={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t("Common.ok")}
                            </Button>
                        </ButtonContainer>
                    </>
                )}
            </Modal>
            <ErrorModal
                hide={() => setErrorModalVisible(false)}
                visible={errorModalVisible}
                title={t("Common.error")}
                description={t("AddEntitlementsView.tokenRateFailureMessage")}
            />
        </>
    );
}
