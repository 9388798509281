import classNames from "classnames";
import moment from "moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { exportFile } from "components/export/common";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import ExportIcon from "components/icons/ExportIcon";
import { SuccessIcon } from "components/icons/SuccessIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import layoutStyle from "components/layout/layout.scss";
import { createLicenseTypes } from "components/licenses/common";
import LicenseEventsTable from "components/licenses/license-history/LicenseEventsTable";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { LICENSES_ROUTE } from "components/router/Routes";
import { EVENT_ACTIONS, EVENTS, FilterState, LicenseEventQuery, TableState } from "domain/licenseEvent";
import { licenseEventsService } from "services/licenses/LicenseEventService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { formatDate } from "utils/format";

import testIds from "testIds.json";

const CSV_LIMIT = 100_000;

export default function LicenseEventsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const productIdList = createLicenseTypes(true).map((each) => each.productId);
    const [state, setTableState] = React.useState<TableState>({
        licenseEvents: [],
        scrollPosition: 0,
    });
    const [filterState, setFilterState] = React.useState<FilterState>({
        quickSearchFilter: "",
        eventTypes: EVENTS,
        productIds: productIdList,
        actions: EVENT_ACTIONS,
        startDate: formatDate(moment().add(-30, "days").startOf("day")),
        endDate: formatDate(moment().endOf("day")),
    });
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const [exportFailed, setExportFailed] = React.useState<boolean>(false);
    const [filtersMissing, setFiltersMissing] = React.useState(false);
    const [refreshCount, setRefreshCount] = React.useState(0);
    const [exportLoading, setExportLoading] = React.useState(false);
    const [accurate, setAccurate] = React.useState(false);
    const [exportModalVisible, setExportModalVisible] = React.useState(false);
    const [startExport, setStartExport] = React.useState(false);
    const [eventCount, setEventCount] = React.useState<number>(0);

    const formSearchQuery = () => {
        const searchQuery: LicenseEventQuery = {
            search: filterState.quickSearchFilter,
            actions: filterState.eventTypes,
            productIds: filterState.productIds,
            eventActions: filterState.actions,
            from: filterState.startDate,
            to: filterState.endDate,
            cursor: state.cursor,
        };
        const abortController = new AbortController();
        abortControllers.push(abortController);
        exportLicenseEvents(searchQuery, abortController);
    };

    React.useEffect(() => {
        if (startExport) {
            formSearchQuery();
            setStartExport(false);
        }
    }, [startExport]);

    const exportLicenseEvents = (searchQuery: LicenseEventQuery, abortController: AbortController) => {
        const countController = new AbortController();
        abortControllers.push(countController);
        try {
            if (!accurate && !startExport) {
                setExportModalVisible(true);
                return false;
            }
            const response = licenseEventsService.exportLicenseEvents(searchQuery, abortController);

            Promise.all([response]).then(([exportResponse]) => {
                const responseText = exportResponse.text();
                responseText.then((text) => {
                    const cleanBase64 = text.replace(/\s/g, "");
                    const byteCharacters = atob(cleanBase64);
                    const byteNumbers = new Uint8Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const blob = new Blob([byteNumbers], { type: "application/zip" });
                    const url = URL.createObjectURL(blob);
                    exportFile(url, "license_history.zip");
                });
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("LicenseEvent.export.exportCount", {
                            actualCount: eventCount > CSV_LIMIT ? CSV_LIMIT : eventCount,
                        })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            });
        } catch {
            if (!abortController.signal.aborted) {
                setExportFailed(true);
                if (filterState.eventTypes.length == 0 || filterState.productIds.length == 0) {
                    setFiltersMissing(true);
                }
            }
        }
    };

    const [fetchCount, setFetchCount] = React.useState(0);

    function onFetchData() {
        setFetchCount(fetchCount + 1);
    }

    function onRefreshing() {
        setRefreshCount(refreshCount + 1);
    }

    const exportButton = (
        <div>
            {exportLoading ? (
                <LoadingIndicator small={true} />
            ) : (
                <button
                    data-tip={t("LicenseEvent.export.tooltip")}
                    className={classNames(buttons.primaryButton, buttons.small)}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.LICENSE_EVENTS,
                            action: Action.EXPORT_LICENSE_EVENTS,
                        });
                        formSearchQuery();
                    }}
                    data-testid={testIds.workArea.license.licenseEvents.exportButton}
                >
                    <ExportIcon color={theme.primaryButtonForegroundColor} />
                    {t("LicenseEvent.export.title")}
                </button>
            )}
        </div>
    );

    return (
        <ApplicationLayout
            viewTitle={t("LicenseEvent.title")}
            view={
                <LicenseEventsTable
                    filterState={filterState}
                    setFilterState={setFilterState}
                    exportFailed={exportFailed}
                    setExportFailed={setExportFailed}
                    filtersMissing={filtersMissing}
                    setFiltersMissing={setFiltersMissing}
                    tableState={state}
                    setTableState={setTableState}
                    fetchCount={fetchCount}
                    refreshCount={refreshCount}
                    setRefreshCount={setRefreshCount}
                    setExportLoading={setExportLoading}
                    setAccurate={setAccurate}
                    exportModalVisible={exportModalVisible}
                    setExportModalVisible={setExportModalVisible}
                    setEventCount={setEventCount}
                    setStartExport={setStartExport}
                />
            }
            onHandleRefresh={onRefreshing}
            enableRefresh={true}
            onFetch={onFetchData}
            mainRoute={LICENSES_ROUTE}
            viewActions={exportButton}
        />
    );
}
