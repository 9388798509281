import classNames from "classnames";
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikProps, FormikValues } from "formik";
import { FormikHelpers } from "formik/dist/types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { ProvisionProperties } from "./CreateNewPasswordView";
import style from "components/login/create-new-password/create-new-password.scss";
import loginStyle from "components/login/login.scss";
import buttons from "styles/buttons.scss";
import buttonsStyle from "styles/buttons.scss";
import form from "styles/form.scss";

import hideIcon from "assets/images/icons/hide_eye.svg";
import showIcon from "assets/images/icons/show_eye.svg";

import testIds from "testIds.json";

interface Props {
    validatePassword: (value: string) => void;
    validateConfirmPassword: (value: string) => void;
    submitDisabled: boolean;
    passwordCallback: (value: string) => void;
    provisionProperties: ProvisionProperties[];
    changePassword?: boolean;
    updatePasswordDetails: (oldPassword: string, newPassword: string) => void;
    errorMessage?: string;
    setChangePasswordFormVisibility?: () => void;
}

const NEW_PASSWORD_ID = "newPassword";
const CONFIRM_PASSWORD_ID = "confirmPassword";
const OLD_PASSWORD_ID = "oldPassword";
const MAXIMUM_NUMBER_OF_RULES = 4;
const MINIMUM_NUMBER_OF_RULES = 0;

const CreateNewPasswordForm = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [passwordVisible, togglePasswordVisibility] = useState<boolean>(false);
    const [confirmPasswordVisible, toggleConfirmPasswordVisibility] = useState<boolean>(false);
    const [oldPasswordVisible, toggleOldPasswordVisibility] = useState<boolean>(false);

    const onFormSubmit = (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
        if (values[NEW_PASSWORD_ID] !== values[CONFIRM_PASSWORD_ID]) {
            formikHelpers.setErrors({ [CONFIRM_PASSWORD_ID]: t("CreateNewPasswordForm.passwordsMustMatch") });
            return;
        }
        props.changePassword
            ? props.updatePasswordDetails(values[OLD_PASSWORD_ID], values[NEW_PASSWORD_ID])
            : props.passwordCallback(values[NEW_PASSWORD_ID]);
    };

    const found = props.provisionProperties.find((e) => !e.fulfilled());
    const translationKey = found ? found.translationKey : "";
    const strengthIndicatorIndex = -1 + props.provisionProperties.filter((e) => e.fulfilled()).length;

    const [hideIndicator, setHideIndicator] = React.useState(false);

    const showIndicator = () => {
        return (
            !hideIndicator &&
            strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES &&
            strengthIndicatorIndex <= MAXIMUM_NUMBER_OF_RULES
        );
    };

    return (
        <Formik
            initialValues={{ [NEW_PASSWORD_ID]: "", [CONFIRM_PASSWORD_ID]: "", [OLD_PASSWORD_ID]: "" }}
            onSubmit={onFormSubmit}
            validationSchema={yup.object({
                [OLD_PASSWORD_ID]: yup.string(),
                [NEW_PASSWORD_ID]: yup.string(),
                [CONFIRM_PASSWORD_ID]: yup
                    .string()
                    .oneOf([yup.ref(NEW_PASSWORD_ID)], t("CreateNewPasswordForm.passwordsMustMatch")),
            })}
            validateOnBlur={true}
        >
            {({ errors }: FormikProps<FormikValues>) => (
                <>
                    <Form>
                        <div className={style.createNewPasswordGrid}>
                            {props.changePassword && (
                                <>
                                    <label
                                        htmlFor={OLD_PASSWORD_ID}
                                        className={classNames(form.label, style.createNewPasswordLabel)}
                                    >
                                        {t("CreateNewPasswordForm.oldPassword")}
                                    </label>
                                    <div>
                                        <Field name={OLD_PASSWORD_ID} validate={props.validateConfirmPassword}>
                                            {({ field }: FieldProps) => (
                                                <div className={classNames(loginStyle.field)}>
                                                    <img
                                                        className={style.passwordVisibilityToggle}
                                                        onClick={() =>
                                                            toggleOldPasswordVisibility((prevState) => !prevState)
                                                        }
                                                        src={oldPasswordVisible ? hideIcon : showIcon}
                                                    />
                                                    <input
                                                        id={OLD_PASSWORD_ID}
                                                        type={oldPasswordVisible ? "text" : "password"}
                                                        {...field}
                                                        onFocus={() => {
                                                            setHideIndicator(true);
                                                        }}
                                                        className={classNames(
                                                            form.input,
                                                            form.input,
                                                            style.createNewPasswordInput,
                                                            {
                                                                [style.invalidInput]: errors[OLD_PASSWORD_ID],
                                                            }
                                                        )}
                                                        data-testid={testIds.createPassword.confirmPasswordInput.itself}
                                                    />
                                                </div>
                                            )}
                                        </Field>
                                        <div
                                            className={form.error}
                                            data-testid={testIds.createPassword.confirmPasswordInput.errorLabel}
                                        >
                                            {props.errorMessage !== "" ? <div>{props.errorMessage}</div> : undefined}
                                        </div>
                                    </div>
                                </>
                            )}
                            <label
                                htmlFor={NEW_PASSWORD_ID}
                                className={classNames(form.label, style.createNewPasswordLabel)}
                            >
                                {t("CreateNewPasswordForm.newPassword")}
                            </label>
                            <div>
                                <Field name={NEW_PASSWORD_ID} validate={props.validatePassword}>
                                    {({ field }: FieldProps) => (
                                        <div className={classNames(loginStyle.field)}>
                                            <img
                                                className={style.passwordVisibilityToggle}
                                                onClick={() => togglePasswordVisibility((prevState) => !prevState)}
                                                src={passwordVisible ? hideIcon : showIcon}
                                            />

                                            <input
                                                id={NEW_PASSWORD_ID}
                                                type={passwordVisible ? "text" : "password"}
                                                {...field}
                                                onFocus={() => {
                                                    setHideIndicator(false);
                                                }}
                                                className={classNames(form.input, style.createNewPasswordInput, {
                                                    [style.invalidInput]:
                                                        errors[CONFIRM_PASSWORD_ID] ||
                                                        (strengthIndicatorIndex < MAXIMUM_NUMBER_OF_RULES &&
                                                            strengthIndicatorIndex > MINIMUM_NUMBER_OF_RULES),
                                                    [style.validInput]:
                                                        !errors[CONFIRM_PASSWORD_ID] &&
                                                        strengthIndicatorIndex >= MAXIMUM_NUMBER_OF_RULES,
                                                })}
                                                data-testid={testIds.createPassword.passwordInput.itself}
                                            />
                                        </div>
                                    )}
                                </Field>
                                <div>
                                    <div className={classNames({ [style.strengthMeter]: showIndicator() })}>
                                        <div
                                            className={classNames(style.strengthMeterUnfilled, {
                                                [style.strengthMeterFill]:
                                                    strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES,
                                            })}
                                            data-strength={
                                                strengthIndicatorIndex >= MINIMUM_NUMBER_OF_RULES
                                                    ? strengthIndicatorIndex.toString()
                                                    : ""
                                            }
                                        >
                                            {}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            showIndicator()
                                                ? classNames(style.strengthMeterText, form.error, {
                                                      [style.weakPassword]:
                                                          strengthIndicatorIndex < MAXIMUM_NUMBER_OF_RULES,
                                                      [style.strongPassword]:
                                                          strengthIndicatorIndex >= MAXIMUM_NUMBER_OF_RULES,
                                                  })
                                                : undefined
                                        }
                                        data-testid={testIds.createPassword.passwordInput.errorLabel}
                                    >
                                        {showIndicator()
                                            ? translationKey !== ""
                                                ? t("CreateNewPasswordView.passwordPolicyIntro") +
                                                  " " +
                                                  t(`CreateNewPasswordView.${translationKey}`)
                                                : t("CreateNewPasswordView.passwordPolicySuccess")
                                            : ""}
                                    </div>
                                </div>
                            </div>
                            <label
                                htmlFor={CONFIRM_PASSWORD_ID}
                                className={classNames(form.label, style.createNewPasswordLabel)}
                            >
                                {t("CreateNewPasswordForm.confirmPassword")}
                            </label>
                            <div>
                                <Field name={CONFIRM_PASSWORD_ID} validate={props.validateConfirmPassword}>
                                    {({ field }: FieldProps) => (
                                        <div className={classNames(loginStyle.field)}>
                                            <img
                                                className={style.passwordVisibilityToggle}
                                                onClick={() =>
                                                    toggleConfirmPasswordVisibility((prevState) => !prevState)
                                                }
                                                src={confirmPasswordVisible ? hideIcon : showIcon}
                                            />
                                            <input
                                                id={CONFIRM_PASSWORD_ID}
                                                type={confirmPasswordVisible ? "text" : "password"}
                                                {...field}
                                                onFocus={() => {
                                                    setHideIndicator(true);
                                                }}
                                                className={classNames(form.input, style.createNewPasswordInput, {
                                                    [style.invalidInput]: errors[CONFIRM_PASSWORD_ID],
                                                })}
                                                data-testid={testIds.createPassword.confirmPasswordInput.itself}
                                            />
                                        </div>
                                    )}
                                </Field>
                                <div
                                    className={form.error}
                                    data-testid={testIds.createPassword.confirmPasswordInput.errorLabel}
                                >
                                    <ErrorMessage name={CONFIRM_PASSWORD_ID} />
                                </div>
                            </div>
                        </div>
                        <div className={form.buttonContainer}>
                            {props.changePassword ? (
                                <>
                                    <button
                                        type="submit"
                                        className={classNames(
                                            props.submitDisabled
                                                ? buttonsStyle.disabledButton
                                                : [buttonsStyle.primaryButton, buttonsStyle.blueButton],
                                            buttons.medium,
                                            form.submitButton
                                        )}
                                        disabled={props.submitDisabled}
                                        data-testid={testIds.createPassword.setPasswordButton}
                                    >
                                        {t("CreateNewPasswordForm.changePassword")}
                                    </button>
                                    <button
                                        className={classNames(buttons.secondaryButton, buttons.medium, style.okButton)}
                                        data-testid={testIds.createPassword.setPasswordButton}
                                        onClick={props.setChangePasswordFormVisibility}
                                    >
                                        {t("Common.cancel")}
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="submit"
                                    className={classNames(
                                        props.submitDisabled
                                            ? buttonsStyle.disabledButton
                                            : [buttonsStyle.primaryButton, buttonsStyle.blueButton],
                                        buttons.medium,
                                        form.submitButton
                                    )}
                                    disabled={props.submitDisabled}
                                    data-testid={testIds.createPassword.setPasswordButton}
                                >
                                    {t("CreateNewPasswordForm.setPassword")}
                                </button>
                            )}
                        </div>
                    </Form>
                </>
            )}
        </Formik>
    );
};

export default CreateNewPasswordForm;
