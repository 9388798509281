import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import PublishedWorkflowTemplate from "./PublishedWorkflowTemplate";
import EmptyStateIcon from "components/icons/EmptyState";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import SearchView from "components/search/SearchView";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import workflowStyle from "components/workflows/all-workflows-table.scss";
import { compareEditorVersionDtos } from "components/workflows/WorkflowUtil";
import { FeatureLicenseType } from "domain/users";
import { TemplateTableData } from "domain/workflowTemplates";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { templateService } from "services/workflows/TemplateService";
import {
    ManifestWorkflowEditor,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    WorkflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import formStyle from "styles/form.scss";
import layoutStyle from "styles/layout.scss";

import testIds from "testIds.json";

interface CardState {
    workflowTemplatesData: TemplateTableData[];
    count: number;
    cursor: string;
    scrollPosition?: number;
}

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
    theme: state.themeReducer.theme,
});
const connector = connect(mapState, { setUser });

const WorkflowTemplatesCardView = (
    props: ConnectedProps<typeof connector> & {
        search: string;
        count: number;
        setCount: React.Dispatch<React.SetStateAction<number>>;
        workflowService: WorkflowService;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [workflowEditors, setWorkflowEditors] = React.useState<ManifestWorkflowEditor[]>([]);
    const [optionList, setOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [search, setSearchQuery] = React.useState("");
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [filterProduct, setFilterProduct] = React.useState("");
    const [filterProductType, setFilterProductType] = React.useState("");
    const [cardState, setCardState] = React.useState<CardState>({
        workflowTemplatesData: [],
        count: 0,
        cursor: "",
        scrollPosition: 0,
    });

    const versionValue = filterProductType.includes("Erasure")
        ? filterProductType.replace("Erasure", "")
        : filterProductType.replace("Diagnostics", "");

    const fetchData = (initialLoading: boolean, abortController: AbortController) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        templateService
            .fetchWorkflowTemplates(
                search,
                initialLoading ? "" : cardState.cursor,
                filterProduct.toUpperCase(),
                true,
                versionValue.trimEnd(),
                "",
                "",
                false,
                "",
                abortController
            )
            .then((data) => {
                console.error("in fetch", data);
                setCardState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.workflowTemplatesData.length - 1,
                    workflowTemplatesData: prevState.workflowTemplatesData.concat(data.workflowTemplatesTableData),
                    count: data.count,
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("workflowTemplatesManager.template.requestFailed"));
                }
            })
            .finally(() => {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };

    React.useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);

        props.workflowService.fetchWorkflowEditors(Profile.ALL, abortController).then((workflowEditors) => {
            setWorkflowEditors(workflowEditors);
        });
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    React.useEffect(() => {
        const sortedEditors = workflowEditors
            .filter((editor) =>
                filterProduct == Profile.BDE ? editor.profile === filterProduct : editor.profile != Profile.BDE
            )
            .sort(compareEditorVersionDtos);

        const removeDuplicateVersion = sortedEditors.filter(
            (obj, index, self) => index === self.findIndex((each) => each.version === obj.version)
        );

        setOptionList(
            removeDuplicateVersion.map((editor) => (
                <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
            ))
        );
    }, [filterProduct]);

    React.useEffect(() => {
        const abortController = new AbortController();
        setCardState({ workflowTemplatesData: [], count: 0, cursor: "", scrollPosition: 0 });
        fetchData(true, abortController);
        return () => {
            abortController.abort();
        };
    }, [props.count, search, filterProduct.toUpperCase(), filterProductType]);

    return (
        <>
            <div className={workflowStyle.titleContainer}>{t("workflowTemplate.title")}</div>
            <div className={layoutStyle.aboveTable}>
                <div className={workflowStyle.filter}>
                    <div className={formStyle.search}>
                        <SearchView
                            setSearch={setSearchQuery}
                            searchInProgress={false}
                            placeholder={t("workflowTemplate.search")}
                        />
                    </div>
                    <div className={formStyle.search}>
                        <select
                            id={"product"}
                            value={filterProduct}
                            onChange={(event) => {
                                setFilterProduct(event.target.value);
                                setFilterProductType("");
                            }}
                            className={classNames(formStyle.select, formStyle.fixedWidthInput)}
                            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.filter.productSelect}
                        >
                            <option key={"empty"} value={""}>
                                {t("workflowTemplatesManager.filter.allProducts")}
                            </option>
                            {Object.values(Profile).map((each, index) => {
                                if (
                                    Profile.ALL !== each &&
                                    props.tenantDetails?.featureLicenses.includes(
                                        profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                    )
                                ) {
                                    return (
                                        <option key={index} value={each}>
                                            {profileToNameMap.get(each)}
                                        </option>
                                    );
                                }
                            })}
                        </select>
                    </div>
                    <div className={formStyle.search}>
                        <select
                            name="version"
                            value={filterProductType}
                            onChange={(e) => setFilterProductType(e.target.value)}
                            className={classNames(form.select, workflowStyle.fixedWidthVersion)}
                            disabled={filterProduct === ""}
                            data-testid={testIds.workArea.workflowTemplatesManager.primaryView.filter.versionSelect}
                        >
                            <option key={"value"} value={""}>
                                {t("workflowTemplatesManager.filter.allVersions")}
                            </option>
                            {optionList}
                        </select>
                    </div>
                </div>
            </div>
            <div className={layoutStyle.tableWrapper}>
                {cardState.workflowTemplatesData.length > 0 || initialLoading ? (
                    <PublishedWorkflowTemplate
                        setCount={props.setCount}
                        data={cardState.workflowTemplatesData}
                        loaded={!initialLoading}
                    />
                ) : (
                    <div className={workflowStyle.errorMessage}>
                        <EmptyStateIcon
                            ellipseBackgroundColor={props.theme.emptyStateEllipseBackgroundColor}
                            biggestCircleBackgroundColor={props.theme.emptyStateBiggestCircleBackgroundColor}
                            smallestCircleBackgroundColor={props.theme.emptyStateSmallestCircleBackgroundColor}
                            personBackgroundColor={props.theme.emptyStatePersonColor}
                            personShirtColor={props.theme.emptyStatePersonShirtColor}
                            personSleeveColor={props.theme.emptyStatePersonSleeveColor}
                            ellipseBackgroundOpacity={props.theme.emptyStateEllipseBackgroundOpacity}
                            biggestCircleBackgroundOpacity={props.theme.emptyStateBiggestCircleBackgroundOpacity}
                            smallestCircleBackgroundOpacityFirst={
                                props.theme.emptyStateSmallestCircleBackgroundOpacityFirst
                            }
                            smallestCircleBackgroundOpacitySecond={
                                props.theme.emptyStateSmallestCircleBackgroundOpacitySecond
                            }
                            smallestCircleBackgroundOpacityThird={
                                props.theme.emptyStateSmallestCircleBackgroundOpacityThird
                            }
                        />
                        <div className={workflowStyle.errorMessageText}>
                            <TextBlock disableBottomSpacing={false}>
                                {requestFailureMessage !== undefined && requestFailureMessage != ""
                                    ? t("workflowTemplatesManager.template.requestFailed")
                                    : t("workflowTemplatesManager.template.emptyStateMessage")}
                            </TextBlock>
                        </div>
                    </div>
                )}
            </div>
            {cardState.cursor != null &&
                cardState.workflowTemplatesData.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={classNames(buttonStyle.primaryButton, buttonStyle.loadMoreButton)}
                        onClick={() => {
                            fetchData(false, new AbortController());
                            usageStatisticsService.sendEvent({
                                label: "workflows",
                                action: Action.LOAD_MORE,
                                category: Category.WORKFLOW,
                            });
                        }}
                        data-testid={testIds.common.primaryView.table.loadMoreButton}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
        </>
    );
};

export default connector(WorkflowTemplatesCardView);
