import React from "react";
import { TFunction, useTranslation } from "react-i18next";

import { BUNDLES_ADD_ONS } from "components/licenses/bundles";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import { TenantsLicenses } from "components/tenants/edit-tenant/EditTenantView";
import { ComputedLicenses, DeliveryType, MIN_DATE } from "domain/licenses";
import { LicensingModel, TenantType } from "domain/tenants";
import { ProductToRateList } from "services/licenses/LicenseService";
import form from "styles/form.scss";
import { isExpired } from "utils/format";

import testIds from "testIds.json";

export const BDE_WORKFLOW_LICENSE_ID = "feature-workflow-bde";
export const BMDE_WORKFLOW_LICENSE_ID = "feature-workflow-bmde";

export const FEATURE_CUSTOM_REPORT_VIEWS = "feature-custom-report-views";
export const FEATURE_LICENSE_CONSUMPTION = "feature-license-consumption";
export const FEATURE_CUSTOM_ROLES = "feature-custom-roles";
export const FEATURE_OVERVIEW_DASHBOARD = "feature-overview-dashboard";
export const FEATURE_PUBLIC_APIS = "feature-public-apis";
export const FEATURE_REPORTING = "feature-reporting";
export const FEATURE_REPORT_CUSTOMIZATION = "feature-report-customization";
export const FEATURE_SSO = "feature-sso";
export const FEATURE_SUSTAINABILITY_DASHBOARD = "feature-sustainability-dashboard";
export const FEATURE_TENANT_MANAGEMENT = "feature-tenant-management";
export const FEATURE_USER_GROUPS = "feature-user-groups";
export const FEATURE_WORKFLOW_BDE = "feature-workflow-bde";
export const FEATURE_WORKFLOW_BMDE = "feature-workflow-bmde";
export const FEATURE_LICENSE_POOLS = "feature-license-pools";
export const FEATURE_LICENSE_ALERTS = "feature-license-alerts";
export const BMS_LICENSE_TYPE_ALL = "bms-all";
export const BMS_LICENSE_TYPE_VALIDATION = "bms-validation";
export const BMS_LICENSE_TYPE_INSURANCE = "bms-insurance";
export const BMS_LICENSE_TYPE_LEASE = "bms-lease";
export const BMS_LICENSE_TYPE_NTF = "bms-ntf";
export const BMS_LICENSE_TYPE_BBTI = "bms-bbti";
export const BMS_LICENSE_FEATURE_ALL = "all";
export const BMS_LICENSE_FEATURE_VALIDATION = "Validation";
export const BMS_LICENSE_FEATURE_INSURANCE = "PreSalesInsurance";
export const BMS_LICENSE_FEATURE_LEASE = "Lease";
export const BMS_LICENSE_FEATURE_NTF = "FaultCheck";
export const BMS_LICENSE_FEATURE_RECOMMENDATIONS = "Recommendations";
export const BMS_LICENSE_FEATURE_BBTI = "TradeIn";
export const BMS_LICENSE_FEATURE_GATHER_IMEI = "gathering_imei";
export const BMS_LICENSE_FEATURE_IDENTIFICATION_VERIFICATION = "infogathering";

export const NON_DELIVERABLE_FEATURE_LICENSES = [
    FEATURE_PUBLIC_APIS,
    FEATURE_OVERVIEW_DASHBOARD,
    FEATURE_LICENSE_CONSUMPTION,
];

export const FEATURE_LICENSES = [
    FEATURE_CUSTOM_REPORT_VIEWS,
    FEATURE_LICENSE_CONSUMPTION,
    FEATURE_CUSTOM_ROLES,
    FEATURE_OVERVIEW_DASHBOARD,
    FEATURE_PUBLIC_APIS,
    FEATURE_REPORTING,
    FEATURE_REPORT_CUSTOMIZATION,
    FEATURE_SSO,
    FEATURE_SUSTAINABILITY_DASHBOARD,
    FEATURE_TENANT_MANAGEMENT,
    FEATURE_USER_GROUPS,
    FEATURE_WORKFLOW_BDE,
    FEATURE_WORKFLOW_BMDE,
    FEATURE_LICENSE_POOLS,
    FEATURE_LICENSE_ALERTS,
];

export function getFeatureLicense() {
    const featureLicenses: Map<string | undefined, string[]> = new Map();
    featureLicenses.set("/workflows", ["FEATURE_WORKFLOW_BMDE", "FEATURE_WORKFLOW_BDE"]);
    featureLicenses.set("/reports/all", ["FEATURE_REPORTING"]);
    featureLicenses.set("/dashboard/sustainability/own", ["FEATURE_SUSTAINABILITY_DASHBOARD"]);
    featureLicenses.set("/dashboard/overview/custom-overview", ["FEATURE_OVERVIEW_DASHBOARD"]);
    return featureLicenses;
}

export function getDeliveryTypes(inTenantCreation?: boolean): Map<string, string> {
    const { t } = useTranslation();
    if (inTenantCreation) {
        return new Map<DeliveryType, string>([["NEW_DEAL", t("DeliveryHistory.deliveryType.newDeal")]]);
    }
    return new Map<DeliveryType, string>([
        ["NEW_DEAL", t("DeliveryHistory.deliveryType.newDeal")],
        ["REIMBURSEMENT", t("DeliveryHistory.deliveryType.reimbursement")],
        ["SUPPLEMENTARY", t("DeliveryHistory.deliveryType.supplementary")],
        ["CORRECTION", t("DeliveryHistory.deliveryType.correction")],
        ["CONVERSION", t("DeliveryHistory.deliveryType.conversion")],
    ]);
}

export function createLicenseTypes(includeOthers: boolean): { productName: string; productId: string }[] {
    const types = [
        { productName: "Drive Eraser - Asset Profiler", productId: "1" },
        { productName: "Mobile Diagnostics & Erasure - Device Image Validation", productId: "2" },
        { productName: "Drive Eraser - PC Edition (per drive)", productId: "3" },
        { productName: "Blancco Drive Eraser - License Reuse", productId: "5" },
        { productName: "Network Device Eraser - Erasure", productId: "6" },
        { productName: "Blancco Common License", productId: "7" },
        { productName: "Blancco Eraser for Apple Devices - Detailed Hardware Check", productId: "10" },
        { productName: "Removable Media Eraser - Enterprise Edition", productId: "11" },
        { productName: "Removable Media Eraser - Volume Edition (per drive)", productId: "12" },
        { productName: "Mobile Diagnostics & Erasure - Carrier ID Check", productId: "16" },
        { productName: "Mobile Diagnostics & Erasure - FMiP Check", productId: "17" },
        { productName: "Drive Eraser - Volume Edition", productId: "20" },
        { productName: "Mobile Diagnostics & Erasure - Carrier Insights Extension", productId: "24" },
        { productName: "LUN Eraser - Erasure (per GB)", productId: "26" },
        { productName: "File Eraser - Home Edition", productId: "27" },
        { productName: "File Eraser - Data Center Edition", productId: "28" },
        { productName: "Mobile Diagnostics & Erasure - Erasure", productId: "30" },
        { productName: "Mobile Diagnostics & Erasure - Asset Profiler", productId: "31" },
        { productName: "Mobile Diagnostics & Erasure - License Reuse", productId: "32" },
        { productName: "Mobile Diagnostics - Integration", productId: "33" },
        { productName: "Mobile Diagnostics & Erasure - Diagnostics", productId: "34" },
        { productName: "Mobile Diagnostics & Erasure - Activation Lock", productId: "35" },
        { productName: "Drive Eraser - Enterprise Volume Edition", productId: "36" },
        { productName: "Drive Verifier - Verification", productId: "37" },
        { productName: "LUN Eraser - Erasure (per erasure)", productId: "38" },
        { productName: "Virtual Machine Eraser - Erasure (per GB)", productId: "39" },
        { productName: "Virtual Machine Eraser - Erasure (per erasure)", productId: "40" },
        { productName: "Check For FeliCa", productId: "42" },
        { productName: "Mobile For FeliCa", productId: "43" },
        { productName: "File Eraser - Enterprise Edition", productId: "44" },
        { productName: "Mobile Diagnostics & Erasure - Enterprise Edition", productId: "46" },
        { productName: "Degausser", productId: "48" },
        { productName: "PreInstall", productId: "49" },
        { productName: "Drive Eraser - Enterprise Subscription Edition", productId: "50" },
        { productName: "Management Console - On-Premise", productId: "51" },
        { productName: "Cloud Storage Eraser - Enterprise Edition", productId: "62" },
        { productName: "Blancco Game Console Eraser", productId: "70" },
        { productName: "Blancco Autopilot Detection - Subscription", productId: "81" },
        { productName: "Technical Account Manager", productId: "90" },
    ];

    if (includeOthers) {
        types.push(
            ...[
                {
                    productName: "Mobile Solutions - Validation",
                    productId: "bms-validation",
                },
                { productName: "Mobile Solutions - Buy-Back / Trade In", productId: "bms-bbti" },
                { productName: "Mobile Solutions - Insurance", productId: "bms-insurance" },
                { productName: "Mobile Solutions - My Device Health", productId: "bms-ntf" },
                { productName: "Mobile Solutions - Lease", productId: "bms-lease" },
                { productName: "Mobile Solutions - All Features", productId: "bms-all" },
                { productName: "Workflows - Drive Eraser", productId: BDE_WORKFLOW_LICENSE_ID },
                { productName: "Workflows - Mobile Diagnostics & Erasure", productId: BMDE_WORKFLOW_LICENSE_ID },
                {
                    productName: "Management Portal - Sustainability dashboard",
                    productId: FEATURE_SUSTAINABILITY_DASHBOARD,
                },
                {
                    productName: "Management Portal - Overview dashboard",
                    productId: FEATURE_OVERVIEW_DASHBOARD,
                },
                { productName: "Management Portal - Public APIs", productId: FEATURE_PUBLIC_APIS },
                { productName: "Management Portal - Reporting", productId: FEATURE_REPORTING },
                { productName: "Mobile Software Development Kit (SDK)", productId: "usdk" },
                { productName: "Management Portal - Report customization", productId: FEATURE_REPORT_CUSTOMIZATION },
                { productName: "Management Portal - Custom report views", productId: FEATURE_CUSTOM_REPORT_VIEWS },
                { productName: "Management Portal - Custom roles", productId: FEATURE_CUSTOM_ROLES },
                { productName: "Management Portal - Single Sign-On (SSO)", productId: FEATURE_SSO },
                { productName: "Management Portal - Tenant management", productId: FEATURE_TENANT_MANAGEMENT },
                { productName: "Management Portal - User groups", productId: FEATURE_USER_GROUPS },
                { productName: "Management Portal - License pools", productId: FEATURE_LICENSE_POOLS },
                { productName: "Management Portal - License alerts", productId: FEATURE_LICENSE_ALERTS },
            ]
        );
    }

    return types;
}

export function createProductIdToNameMap(): Map<string, string> {
    const licenses = createLicenseTypes(true);
    return new Map<string, string>(licenses.map((each) => [each.productId, each.productName]));
}

export function createSortedLicenseTypes(): { productName: string; productId: string }[] {
    const licenses = createLicenseTypes(true);
    return licenses.sort((a, b) => a.productName.localeCompare(b.productName));
}

export function isSubscriptionLicense(type: string): boolean {
    return ["46", "50", "51", "62", "81", "90"].includes(type);
}

function toDate(date: Date | string): Date {
    if (typeof date === "string") {
        return new Date(date);
    }
    return date;
}

export const isExpirationDateValid = (expiration: Date | string, maxDate: Date | string): boolean => {
    const expirationDate = toDate(expiration);
    const maxDateAdjusted = toDate(maxDate);
    maxDateAdjusted.setDate(maxDateAdjusted.getDate() + 1);
    return expirationDate >= MIN_DATE && expirationDate < maxDateAdjusted;
};

export const isAmountValid = (item: License, usedCount?: number, inLicensePool?: boolean): boolean => {
    return inLicensePool ? (usedCount ? item.licensesToAdd >= usedCount : true) : true;
};

export const isAmountAvailable = (item: License, inLicensePool?: boolean): boolean => {
    return item.licensesToAdd <= deduceErrorThreshold(item, inLicensePool);
};

function deduceErrorThreshold(license: License, inLicensePool?: boolean) {
    return inLicensePool ? license.available : license.parentAvailableAmount;
}

export function computeBundleAddOnLicenses(
    allTenantLicenses: TenantsLicenses[],
    tierLicenses: string[] | undefined
): ComputedLicenses[] {
    return BUNDLES_ADD_ONS.map((addOnType) => {
        const sum = allTenantLicenses
            .filter(
                (license) =>
                    license.productId === addOnType &&
                    !isExpired(license.expirationDate) &&
                    tierLicenses?.includes(license.productId)
            )
            .reduce((sum, license) => sum + license.available, 0);
        return {
            licenseId: addOnType,
            amount: sum,
        };
    }).filter((license) => license.amount !== 0);
}

export function computeEquivalentOfCommonLicenses(
    allTenantLicenses: TenantsLicenses[],
    productToRateList: ProductToRateList
) {
    let equivalentAmount = 0;
    allTenantLicenses.forEach((eachLicense) => {
        if (productToRateList) {
            const latestRates = productToRateList.productToRate;
            const rate = latestRates.find((eachRate) => eachRate.productId === eachLicense.productId)?.rate;
            if (rate) {
                equivalentAmount += eachLicense.remainingLicenses * rate;
            }
        }
    });
    return Math.round(equivalentAmount);
}

export const displayAmountErrorLabel = (
    license: License,
    t: TFunction,
    inLicensePool?: boolean,
    usedLicenseCountInPool?: number
) => {
    return isNaN(license.licensesToAdd) ? (
        <div
            className={form.error}
            data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.assignedAmountInput.errorLabel}
        >
            <span>{t("DeliveryHistory.addLicenseDelivery.validation.licensesToAdd")}</span>
        </div>
    ) : isAmountValid(license, usedLicenseCountInPool, inLicensePool) ? (
        isAmountAvailable(license, inLicensePool ?? inLicensePool) ? null : (
            <div
                className={form.error}
                data-testid={
                    inLicensePool
                        ? testIds.workArea.license.licensePools.manageLicensePoolView.table.assignedAmountInput
                              .errorLabel
                        : testIds.workArea.license.deliveryHistory.createDeliveryDialog.assignedAmountInput.errorLabel
                }
            >
                <span>
                    {t("DeliveryHistory.addLicenseDelivery.validation.tooManyLicenses", {
                        threshold: deduceErrorThreshold(license, inLicensePool),
                    })}
                </span>
            </div>
        )
    ) : (
        <div className={form.error}>
            <span>
                {t("DeliveryHistory.addLicenseDelivery.validation.lessThanUsedLicenses", {
                    used: usedLicenseCountInPool,
                })}
            </span>
        </div>
    );
};

export const bmsLicenseTypesAndFeatureKeys = [
    { type: BMS_LICENSE_TYPE_VALIDATION, feature: BMS_LICENSE_FEATURE_VALIDATION },
    { type: BMS_LICENSE_TYPE_LEASE, feature: BMS_LICENSE_FEATURE_LEASE },
    { type: BMS_LICENSE_TYPE_BBTI, feature: BMS_LICENSE_FEATURE_BBTI },
    { type: BMS_LICENSE_TYPE_INSURANCE, feature: BMS_LICENSE_FEATURE_INSURANCE },
    { type: BMS_LICENSE_TYPE_NTF, feature: BMS_LICENSE_FEATURE_NTF },
    { type: BMS_LICENSE_TYPE_NTF, feature: BMS_LICENSE_FEATURE_RECOMMENDATIONS },
];

export function isUsingOldLicensingModel(tenantType: TenantType) {
    return tenantType == "INTERNAL" || tenantType == "CHANNEL" || tenantType == "CUSTOMER";
}

export function showOnlyTokenLicense(licensingModel?: LicensingModel) {
    return licensingModel === LicensingModel.BUNDLE_WITH_TOKEN;
}

export const BLANCCO_TOKEN_ID = "7";

export function isCommonLicense(type: string) {
    return type === BLANCCO_TOKEN_ID;
}
