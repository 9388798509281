import { SelectSearchOption } from "react-select-search";

import { HOUR, MINUTES_SECONDS_MILLISECONDS } from "./format";
import { BUNDLES_ADD_ONS } from "components/licenses/bundles";

export type OptionalString = string | undefined | null;

const FAULT_CODE_TO_TRANSLATION_MAP = new Map([
    ["fault_check__fault_code_all_tests", "DiagnosticReportsTable.myDeviceHealth.faultCodes.allTests"],
    ["fault_check__fault_code_automated_tests", "DiagnosticReportsTable.myDeviceHealth.faultCodes.automatedTests"],
    ["fault_check__fault_code_camera", "DiagnosticReportsTable.myDeviceHealth.faultCodes.camera"],
    ["fault_check__fault_code_ports", "DiagnosticReportsTable.myDeviceHealth.faultCodes.ports"],
    ["fault_check__fault_code_screen", "DiagnosticReportsTable.myDeviceHealth.faultCodes.screen"],
    ["fault_check__fault_code_audio", "DiagnosticReportsTable.myDeviceHealth.faultCodes.audio"],
    ["fault_check__fault_code_device", "DiagnosticReportsTable.myDeviceHealth.faultCodes.device"],
    ["fault_check__fault_code_sensors", "DiagnosticReportsTable.myDeviceHealth.faultCodes.sensors"],
    ["fault_check__fault_code_others", "DiagnosticReportsTable.myDeviceHealth.faultCodes.others"],
]);

export function isStringNotBlank(value: OptionalString): boolean {
    const trimmed = trimToNull(value);
    return trimmed != null;
}

export function isStringBlank(value: OptionalString): boolean {
    const trimmed = trimToNull(value);
    return trimmed === null;
}

export function trimToNull(value: OptionalString): string | null {
    if (value == null) {
        return null;
    }
    const trimmed = value.trim();
    return trimmed === "" ? null : trimmed;
}

export function trimToUndefined(value: OptionalString): string | undefined {
    if (value == null) {
        return undefined;
    }
    const trimmed = value.trim();
    return trimmed === "" ? undefined : trimmed;
}

export function hasOnlyNumbers(value: OptionalString): boolean {
    return /^\d+$/.test(value ?? "");
}

export function hasOnlyValidWorkflowCharacters(value: OptionalString) {
    return /^[^\0"%*/:<>?\\|]*$/.test(value ?? "");
}

export function getInitials(value: string) {
    const initials = value.split(" ").slice(0, 2);
    if (initials.length === 1) {
        initials.push(value.substring(1));
    }
    return initials
        .map((e) => e[0])
        .join("")
        .toUpperCase();
}

export function filterOptions(options: SelectSearchOption[], searchInput: string): SelectSearchOption[] {
    const lowered = searchInput.toLowerCase();
    return options.filter(
        (each) =>
            each.value.toString().toLowerCase().includes(lowered) || each.name.toLocaleLowerCase().includes(lowered)
    );
}

export function createFilterOptions(options: SelectSearchOption[]): (searchInput: string) => SelectSearchOption[] {
    return (searchInput: string) => filterOptions(options, searchInput);
}

export class ParameterQuery {
    private readonly parameters: URLSearchParams;

    constructor() {
        this.parameters = new URLSearchParams();
    }

    add(name: string, value: OptionalString): ParameterQuery {
        const trimmed = trimToNull(value);
        if (trimmed != null) {
            this.parameters.append(name, trimmed);
        }
        return this;
    }

    addAll(pairs: [string, OptionalString][]): ParameterQuery {
        const trimmedPairs: [string, string][] = [];
        for (const [key, value] of pairs) {
            const trimmed = trimToNull(value);
            if (trimmed == null) {
                return this;
            }
            trimmedPairs.push([key, trimmed]);
        }
        trimmedPairs.forEach((pair) => this.parameters.append(...pair));
        return this;
    }

    createQueryString(): string {
        const next = this.parameters.keys().next();
        if (next.done) {
            return "";
        }
        return "?" + this.parameters.toString();
    }
}

export function preventBodyScrolling(currentScrollingState: boolean) {
    const body = document.body;
    if (!currentScrollingState) {
        body.classList.remove("disableScroll");
        return;
    }
    body.classList.add("disableScroll");
}

export function getFaultCodeTranslation(faultCodeKey: string) {
    const translation = FAULT_CODE_TO_TRANSLATION_MAP.get(faultCodeKey);
    return translation !== undefined ? translation : faultCodeKey;
}

export function toDate(value?: string): Date | undefined {
    if (value == null || value === "") {
        return undefined;
    }
    return new Date(value);
}

export function getMaxDate() {
    return new Date("2091-01-01T00:00:00Z");
}

export function isAddOn(productId: string) {
    return BUNDLES_ADD_ONS.includes(productId);
}

export function getNextDay(date: Date) {
    const next = new Date(date.getTime());
    next.setHours(HOUR, MINUTES_SECONDS_MILLISECONDS, MINUTES_SECONDS_MILLISECONDS);
    next.setDate(date.getDate() + 1);
    return next;
}
