import * as React from "react";

const Import = (props: { color: string }): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 21.709 17.883"
            style={{ marginRight: "10px" }}
        >
            <g id="importIcon" transform="translate(0.89 0.769)">
                <g id="Group_7762" data-name="Group 7762" transform="translate(6.002 7.364)">
                    <path
                        id="Path_1880"
                        data-name="Path 1880"
                        d="M8,21l4-4,4,4"
                        transform="translate(-8 -17)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <line
                        id="Line_750"
                        data-name="Line 750"
                        y1="9"
                        transform="translate(4)"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
                <path
                    id="Path_1881"
                    data-name="Path 1881"
                    d="M19.061,16.443a4.408,4.408,0,0,0,1.717-4.989,4.534,4.534,0,0,0-4.334-3.109H15.3A7.225,7.225,0,0,0,9.091,3.035,7.3,7.3,0,0,0,1.814,6.821a7.019,7.019,0,0,0,1,8.018"
                    transform="translate(-0.991 -2.988)"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
};

export default Import;
