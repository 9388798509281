import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { ModularIcon } from "components/icons/ModularIcon";
import { ReadyToUseIcon } from "components/icons/ReadyToUseIcon";
import { SuccessIcon } from "components/icons/SuccessIcon";
import { TitleIcon } from "components/icons/TitleIcon";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { WORKFLOWS_ROUTE } from "components/router/Routes";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import publishTemplateStyle from "components/workflows/workflow-templates/published-workflow-template.scss";
import { AUTH_WORKFLOW_DEFAULT } from "domain/authority";
import { TemplateTableData } from "domain/workflowTemplates";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { templateService, WorkflowTemplateSaveResponse } from "services/workflows/TemplateService";
import { Profile, profileToNameMap, workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props<T extends TemplateTableData> {
    data: T[];
    loaded: boolean;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}
const DOT = " • ";

interface TemplateDetailsDialog {
    show: boolean;
    details?: TemplateTableData;
}

interface ConflictsResponseDto {
    conflicting_workflow_names: string[];
}

interface ConflictsResponse {
    conflictingWorkflowNames: string[];
}

interface ActiveWorkflowDialog {
    show: boolean;
    workflowUuid: string;
}

function PublishedWorkflowTemplate<D extends TemplateTableData>(props: Props<D>): JSX.Element {
    if (!props.loaded) {
        return <LoadingIndicator />;
    }
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const { t } = useTranslation();
    const [templateDetailsDialog, setTemplateDetailsDialog] = React.useState<TemplateDetailsDialog>({
        show: false,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [addedBadge, setAddedBadge] = React.useState<boolean>(false);
    const [showActiveWorkflowDialog, setShowActiveWorkflowDialog] = React.useState<ActiveWorkflowDialog>({
        show: false,
        workflowUuid: "",
    });
    const [conflictsException, setConflictsException] = React.useState<ConflictsResponse | undefined>(undefined);
    const [activeWorkflowLoading, setActiveWorkflowLoading] = React.useState<boolean>(false);

    const getTemplateTypeBadge = (templateType: string): JSX.Element => {
        return templateType === "Ready to use" ? (
            <div className={publishTemplateStyle.readyToUseContainer}>
                <ReadyToUseIcon linecolor={theme.readyToUseSkyBlueColor} />
                <div className={publishTemplateStyle.textSpace}>{templateType.replace(/ /g, "-")}</div>
            </div>
        ) : (
            <div className={publishTemplateStyle.modularContainer}>
                <ModularIcon color={theme.modularPurpleColor} />
                <div className={publishTemplateStyle.textSpace}>{templateType}</div>
            </div>
        );
    };

    const toConflictsResponse = (dto: ConflictsResponseDto): ConflictsResponse => {
        return {
            conflictingWorkflowNames: dto.conflicting_workflow_names,
        };
    };

    const setSavedWorkflowActive = () => {
        setActiveWorkflowLoading(true);
        const abortController = new AbortController();
        workflowService.setDefault(showActiveWorkflowDialog.workflowUuid, abortController).then(() => {
            showSuccessNotificationToast(true);
            setShowActiveWorkflowDialog({
                show: false,
                workflowUuid: "",
            });
            setActiveWorkflowLoading(false);
        });
    };

    const showSuccessNotificationToast = (active: boolean) => {
        let message = t("workflowTemplate.workflowTemplateDetailsDialog.successToast.success", {
            templateName: templateDetailsDialog.details?.name,
        });
        if (active) {
            message += " " + t("workflowTemplate.workflowTemplateDetailsDialog.successToast.active");
        }
        return toast(
            <SuccessIcon
                successClass={layoutStyle.customToastSuccessIcon}
                color={theme.contentBackgroundColor}
                text={message}
            />,
            {
                closeButton: (closeToastProps) => (
                    <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                ),
                className: layoutStyle.customToastSuccessMessage,
            }
        );
    };

    const saveTemplate = () => {
        if (templateDetailsDialog.details) {
            setLoading(true);
            const abortController = new AbortController();

            templateService
                .save(templateDetailsDialog.details.uuid, abortController)
                .then((response: WorkflowTemplateSaveResponse) => {
                    setRequestFailureMessage("");
                    setLoading(false);
                    setAddedBadge(true);
                    !hasTenantCookie()
                        ? setShowActiveWorkflowDialog({
                              show: true,
                              workflowUuid: response.workflowUuid,
                          })
                        : showSuccessNotificationToast(false);
                })
                .catch((exception) => {
                    try {
                        if (!abortController.signal.aborted) {
                            setConflictsException(toConflictsResponse(JSON.parse(exception.message)));
                            setRequestFailureMessage(t("workflowTemplate.workflowTemplateDetailsDialog.error.message"));
                            setAddedBadge(false);
                            setShowActiveWorkflowDialog({
                                show: false,
                                workflowUuid: "",
                            });
                        }
                    } catch (error) {
                        setRequestFailureMessage(t("workflowTemplate.workflowTemplateDetailsDialog.error.failed"));
                        setConflictsException(undefined);
                        setAddedBadge(false);
                        setShowActiveWorkflowDialog({
                            show: false,
                            workflowUuid: "",
                        });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <div className={publishTemplateStyle.gridContainer}>
            {props.data.map((item, index) => (
                <div
                    key={index}
                    className={publishTemplateStyle.gridItem}
                    onClick={() => {
                        setTemplateDetailsDialog({ show: true, details: item });
                        setRequestFailureMessage("");
                    }}
                >
                    <div className={publishTemplateStyle.iconContainer}>
                        {getTemplateTypeBadge(item.templateType)}
                        {item.saved && (
                            <div className={publishTemplateStyle.added}>
                                <StatusBadge
                                    values={[
                                        {
                                            status: Status.SUCCESS,
                                            title: t("workflowTemplate.workflowTemplateDetailsDialog.added"),
                                        },
                                    ]}
                                    tooltip={true}
                                />
                            </div>
                        )}
                    </div>
                    <div className={publishTemplateStyle.dataContainer}>
                        <div className={publishTemplateStyle.deviceItem}>{item.name}</div>
                        <div className={publishTemplateStyle.versionContainer}>
                            <div className={publishTemplateStyle.textstyle}>
                                {profileToNameMap.get(item.profile as Profile)} {item.editorVersion}
                                {item.connectedWorkflows.totalConnectedWorkflows !== 0 &&
                                    DOT +
                                        item.connectedWorkflows.totalConnectedWorkflows +
                                        t("workflowTemplate.subWorkflows")}
                                {item.useCases.map((data: string) => (
                                    <>
                                        {DOT} {data}
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <Modal
                isOpen={templateDetailsDialog.show}
                hideModal={() => {
                    setTemplateDetailsDialog({ show: false, details: undefined });
                    setAddedBadge(false);
                    props.setCount((prev) => ++prev);
                }}
                titleIcon={requestFailureMessage ? <TitleIcon iconType="ERROR" /> : ""}
                modalTitle={
                    requestFailureMessage ? t("workflowTemplate.workflowTemplateDetailsDialog.error.title") : ""
                }
            >
                <div className={publishTemplateStyle.publishedTemplateDetailsDialog}>
                    {loading ? (
                        <div className={publishTemplateStyle.saveTemplate}>
                            <LoadingIndicator />
                            <div className={publishTemplateStyle.message}>
                                {t("workflowTemplate.workflowTemplateDetailsDialog.addingTemplateToWorkflows")}
                            </div>
                        </div>
                    ) : requestFailureMessage ? (
                        <>
                            <div>{requestFailureMessage}</div>
                            {conflictsException && (
                                <div>
                                    <ul>
                                        {conflictsException.conflictingWorkflowNames.map((each, index) => (
                                            <li key={index}>{each}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <ButtonContainer>
                                <Button
                                    variant={"PRIMARY"}
                                    onClick={() => window.location.replace(WORKFLOWS_ROUTE.path)}
                                    testId={testIds.common.dialog.primaryButton}
                                >
                                    {t("workflowTemplate.workflowTemplateDetailsDialog.error.goToWorkflows")}
                                </Button>

                                <Button
                                    variant={"SECONDARY"}
                                    onClick={() => {
                                        setTemplateDetailsDialog({ show: false, details: undefined });
                                        setAddedBadge(false);
                                        props.setCount((prev) => ++prev);
                                    }}
                                    testId={testIds.common.dialog.closeButton}
                                >
                                    {t("Common.cancel")}
                                </Button>
                            </ButtonContainer>
                        </>
                    ) : (
                        templateDetailsDialog.details && (
                            <>
                                <div className={publishTemplateStyle.badges}>
                                    <div>{getTemplateTypeBadge(templateDetailsDialog.details.templateType)}</div>

                                    {(addedBadge || templateDetailsDialog.details.saved) && (
                                        <div className={publishTemplateStyle.added}>
                                            <StatusBadge
                                                values={[
                                                    {
                                                        status: Status.SUCCESS,
                                                        title: t(
                                                            "workflowTemplate.workflowTemplateDetailsDialog.added"
                                                        ),
                                                    },
                                                ]}
                                                tooltip={true}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className={publishTemplateStyle.dataContainer}>
                                    <div className={publishTemplateStyle.title}>
                                        {templateDetailsDialog.details.name}
                                    </div>
                                    <div className={publishTemplateStyle.textstyle}>
                                        {profileToNameMap.get(templateDetailsDialog.details.profile as Profile)}{" "}
                                        {templateDetailsDialog.details.editorVersion}{" "}
                                        {templateDetailsDialog.details.connectedWorkflows.totalConnectedWorkflows !==
                                            0 &&
                                            DOT +
                                                templateDetailsDialog.details.connectedWorkflows
                                                    .totalConnectedWorkflows +
                                                t("workflowTemplate.subWorkflows")}
                                    </div>
                                    {templateDetailsDialog.details.useCases.length > 0 ||
                                    templateDetailsDialog.details.coreActivities.length > 0 ||
                                    templateDetailsDialog.details.processors.length > 0 ? (
                                        <div className={publishTemplateStyle.categories}>
                                            <ul>
                                                {templateDetailsDialog.details.useCases.map(
                                                    (data: string, index: number) => (
                                                        <li className={publishTemplateStyle.item} key={index}>
                                                            {data}
                                                        </li>
                                                    )
                                                )}
                                                {templateDetailsDialog.details.coreActivities.map(
                                                    (data: string, index: number) => (
                                                        <li className={publishTemplateStyle.item} key={index}>
                                                            {data}
                                                        </li>
                                                    )
                                                )}
                                                {templateDetailsDialog.details.processors.map(
                                                    (data: string, index: number) => (
                                                        <li className={publishTemplateStyle.item} key={index}>
                                                            {data}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {templateDetailsDialog.details.description && (
                                        <div className={publishTemplateStyle.description}>
                                            <div className={publishTemplateStyle.title}>
                                                {t("workflowTemplate.workflowTemplateDetailsDialog.description")}
                                            </div>
                                            <div className={publishTemplateStyle.content}>
                                                {templateDetailsDialog.details.description}
                                            </div>
                                        </div>
                                    )}

                                    {templateDetailsDialog.details.connectedWorkflows.subWorkflows.length ||
                                    templateDetailsDialog.details.connectedWorkflows.runProcessFlowWorkflows.length ||
                                    templateDetailsDialog.details.connectedWorkflows.gradingPanelWorkflows.length ||
                                    templateDetailsDialog.details.connectedWorkflows.collectWorkflows.length ? (
                                        <div className={publishTemplateStyle.description}>
                                            <div className={publishTemplateStyle.title}>
                                                {t("workflowTemplate.subWorkflows")}
                                            </div>
                                            <div className={publishTemplateStyle.content}>
                                                <ul>
                                                    {templateDetailsDialog.details.connectedWorkflows.subWorkflows.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {templateDetailsDialog.details.connectedWorkflows.runProcessFlowWorkflows.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {templateDetailsDialog.details.connectedWorkflows.runDiagnoseWorkflows.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {templateDetailsDialog.details.connectedWorkflows.gradingPanelWorkflows.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                    {templateDetailsDialog.details.connectedWorkflows.collectWorkflows.map(
                                                        (data: string, index: number) => (
                                                            <li className={publishTemplateStyle.item} key={index}>
                                                                {data}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {!addedBadge && (
                                    <ButtonContainer>
                                        <Button
                                            variant={"PRIMARY"}
                                            onClick={saveTemplate}
                                            testId={testIds.common.dialog.primaryButton}
                                        >
                                            {t("workflowTemplate.workflowTemplateDetailsDialog.addTemplate")}
                                        </Button>
                                    </ButtonContainer>
                                )}
                            </>
                        )
                    )}
                </div>
            </Modal>
            <Modal
                modalTitle={t("workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.title")}
                isOpen={
                    showActiveWorkflowDialog.show && userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_DEFAULT])
                }
                hideModal={() =>
                    setShowActiveWorkflowDialog({
                        show: false,
                        workflowUuid: "",
                    })
                }
            >
                {activeWorkflowLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div className={publishTemplateStyle.setActiveDialog}>
                        <div>{t("workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.message")}</div>

                        <ButtonContainer>
                            <Button
                                variant={"PRIMARY"}
                                onClick={() => {
                                    showSuccessNotificationToast(false);
                                    setShowActiveWorkflowDialog({
                                        show: false,
                                        workflowUuid: "",
                                    });
                                }}
                                testId={testIds.common.dialog.closeButton}
                            >
                                {t(
                                    "workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.dontSetActiveButton"
                                )}
                            </Button>

                            <Button
                                variant={"SECONDARY"}
                                onClick={() => {
                                    setSavedWorkflowActive();
                                }}
                                testId={testIds.common.confirmationDialog.confirmButton}
                            >
                                {t(
                                    "workflowTemplate.workflowTemplateDetailsDialog.setActiveWorkflow.setAsActiveButton"
                                )}
                            </Button>
                        </ButtonContainer>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default PublishedWorkflowTemplate;
