import {
    EventAction,
    EventType,
    KeyCursor,
    LicenseEvent,
    LicenseEventQuery,
    LicenseEventResult,
} from "domain/licenseEvent";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

const PATH = "/api/license-events/query";

interface LicenseEventQueryDto {
    search: string;
    from: string;
    to: string;
    product_ids: string[];
    event_types: string[];
    event_actions: string[];
    cursor?: LicenseEventCursorDto;
}

interface LicenseEventDto {
    event_uuid: string;
    username: string;
    pool_name: string;
    time: string;
    type: EventType;
    product_id: string;
    amount_before: number;
    amount_after: number;
    change: number;
    expiration_after: string;
    action: EventAction;
    notes?: string;
    license_consumption_id?: string;
    asset_id?: string;
}
export interface LicenseEventCursorDto {
    event_uuid: string;
    tenant_uuid: string;
    time: string;
}

interface LicenseEventsResponseDto {
    license_history: LicenseEventDto[];
    cursor: LicenseEventCursorDto;
}

interface LicenseEventsCountResponseDto {
    total_events: number;
    accurate: boolean;
}

interface LicenseEventsCountResponse {
    totalEvents: number;
    accurate: boolean;
}

function toLicenseEvent(data: LicenseEventDto): LicenseEvent {
    return {
        eventUuid: data.event_uuid,
        productId: data.product_id,
        eventType: data.type,
        change: data.change,
        remaining: data.amount_after,
        eventDate: data.time,
        user: data.username,
        poolName: data.pool_name,
        expirationDate: data.expiration_after,
        notes: data.notes,
        eventAction: data.action,
        licenseConsumptionId: data.license_consumption_id,
        assetId: data.asset_id,
    };
}
function toLicenseCursor(dto: LicenseEventCursorDto): KeyCursor | undefined {
    return dto == null
        ? undefined
        : {
              eventUuid: dto.event_uuid,
              tenantUuid: dto.tenant_uuid,
              time: dto.time,
          };
}
function toQueryDto(data?: LicenseEventQuery): LicenseEventQueryDto | undefined {
    if (typeof data === "undefined") {
        return undefined;
    }
    return {
        search: data.search,
        from: data.from,
        to: data.to,
        product_ids: data.productIds,
        event_types: data.actions,
        event_actions: data.eventActions,
        cursor: toQueryCursorDto(data.cursor),
    };
}

function toQueryCursorDto(data?: KeyCursor): LicenseEventCursorDto | undefined {
    if (typeof data === "undefined") {
        return undefined;
    }
    return {
        event_uuid: data.eventUuid,
        tenant_uuid: data.tenantUuid,
        time: data.time,
    };
}

class LicenseEventService {
    public fetchLicenseEvents(
        query: LicenseEventQuery,
        abortController?: AbortController
    ): Promise<LicenseEventResult> {
        return apiGatewayService
            .invokeApi(PATH, "POST", toQueryDto(query), {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((data: LicenseEventsResponseDto) => {
                const licenseEvents = data.license_history.map((each) => toLicenseEvent(each));
                const cursor = toLicenseCursor(data.cursor);
                return { licenseEvents: licenseEvents, cursor: cursor };
            });
    }

    public fetchEventsCount(
        query: LicenseEventQuery,
        abortController?: AbortController
    ): Promise<LicenseEventsCountResponse> {
        return apiGatewayService
            .invokeApi(PATH + "?count=true", "POST", toQueryDto(query), {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((data: LicenseEventsCountResponseDto) => {
                return {
                    totalEvents: data.total_events,
                    accurate: data.accurate,
                };
            });
    }

    public exportLicenseEvents(query: LicenseEventQuery, abortController: AbortController): Promise<Response> {
        return apiGatewayService.invokeApi(PATH + "?format=csv", "POST", toQueryDto(query), {
            apiType: ApiType.LAUREL,
            jsonResponse: false,
            abortController: abortController,
        });
    }
}

export const licenseEventsService = new LicenseEventService();
