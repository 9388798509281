import classNames from "classnames";
import { useFeature } from "flagged";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

import style from "./endpoints-view.scss";
import ApiPlainCopy from "components/icons/ApiPlainCopy";
import endpointStyle from "components/support/api-guide/endpoints/endpoints-view.scss";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { MAX_COLUMN_COUNT } from "domain/reports";
import { FLAG_COMPARE_REPORTS } from "services/feature/FeatureFlagService";
import { getPublicApiUrl } from "services/login/endpointRepository";

import checkMark from "assets/images/icons/checkMark.svg";

const API_KEY_HEADER = `-H "X-BLANCCO-API-KEY: 8574e755-1f80-4af4-b7ae-a8187afb3909"`;
const COMMAND_PART_CURL = "curl";
const COMMAND_PART_DATA = "-d";
const COMMAND_PART_XPOST = "-XPOST";
const CONTENT_TYPE_JSON_HEADER = `-H "Content-Type: application/json"`;
const INDENT = "    ";
const TOOLTIP_TIMEOUT = 300;
const LANGUAGES = [
    "DE_DE",
    "EN_US",
    "ES_ES",
    "FR_FR",
    "HU_HU",
    "IT_IT",
    "JA_JP",
    "KO_KR",
    "PL_PL",
    "PT_BR",
    "RU_RU",
    "SK_SK",
    "ZH_CN",
    "ZH_TW",
];
const MIN_CSV_COLUMN_COUNT = 1;
const CSV_EXAMPLE_COLUMNS = [
    "report.report_date",
    "report.uuid",
    "report.product_name",
    "blancco_hardware_report.system.imei",
    "blancco_software_report.operating_system.version",
    "user_data.fields.part ID",
]
    .map((each) => `"${each}"`)
    .join(", ");
const MIN_REPORT_COUNT = 1;
const MAX_REPORT_COUNT = 10;
const DEFAULT_REPORT_COUNT = 100;

interface Endpoint {
    linkId: string;
    title: string;
    introduction: string;
    details?: string;
    url: string;
    method: string;
    format: string;
    requestExample?: Record<string, unknown>;
    responseExample: JSX.Element;
    curlSection: JSX.Element;
    parameterSection?: JSX.Element;
    description?: JSX.Element;
}

interface RequestParameter {
    name: string;
    required: boolean;
    description: string;
}

enum CommandPartType {
    PLAINTEXT,
    JSON,
}

class CommandPart {
    value: string;
    type: CommandPartType;
    indent: boolean;

    constructor(value: string, type: CommandPartType, indent = true) {
        this.type = type;
        this.value = value;
        this.indent = indent;
    }
}

const createPlainTextCommandPart = (value: string, indent?: boolean) => {
    return new CommandPart(value, CommandPartType.PLAINTEXT, indent);
};

const createJsonCommandPart = (value: string, indent?: boolean) => {
    return new CommandPart(value, CommandPartType.JSON, indent);
};

function createUrlSection(t: TFunction, endpoint: Endpoint): JSX.Element {
    const copyText = t("Common.copyToClipboard");
    const [copyTooltipText, setCopyTooltipText] = React.useState<string>(copyText);

    function createContent(label: string, value: string, copyClickHandler?: () => void): JSX.Element {
        let copyDiv = null;
        if (copyClickHandler != null) {
            copyDiv = (
                <Tooltip content={copyTooltipText}>
                    <div onClick={copyClickHandler} className={style.urlSubItem}>
                        <ApiPlainCopy />
                    </div>
                </Tooltip>
            );
        }
        return (
            <>
                <div className={classNames(style.urlTitle, style.urlSubItem)}>{label}</div>
                {/* 400px is just a guess */}
                <Tooltip maxWidth={400} content={value}>
                    <div className={classNames(style.urlItemValueContainer, style.urlSubItem)}>{value}</div>
                </Tooltip>
                {copyDiv}
            </>
        );
    }

    function copyUrl() {
        navigator.clipboard.writeText(endpoint.url);
        setCopyTooltipText(t("Common.copied"));
        // This is a bit of a hack but so far it's the only thing that works. When user clicks the copy icon, the
        // tooltip is hidden and then we'll need to change the text before user is able to move mouse cursor from over
        // the tooltip and back to it. Tippy.js has multiple event handler props like onTrigger and onUntrigger but for
        // some reason they weren't of use here.
        // With 500ms timeout it was possible to see the wrong tooltip when you click, move mouse cursor out, and back
        // in really fast. With 300ms it didn't seem possible and the highest feasible value seems the way to go in case
        // at some point there's some kind of fade-out animation when copy icon is clicked. Then the "Copied!" text
        // would remain as long as the tooltip is visible but it's replaced with the "Copy to clipboard" text before the
        // user can have it visible again.
        setTimeout(() => {
            setCopyTooltipText(copyText);
        }, TOOLTIP_TIMEOUT);
    }

    const items = [
        createContent(t("Support.apiGuide.url"), endpoint.url, copyUrl),
        createContent(t("Support.apiGuide.httpMethod"), endpoint.method),
        createContent(t("Support.apiGuide.format"), endpoint.format),
    ];
    return (
        <div className={style.urlSection}>
            {items.map((each, index) => (
                <div key={index} className={style.urlItem}>
                    {each}
                </div>
            ))}
        </div>
    );
}

function createTextExample(title: string, text: string): JSX.Element {
    return (
        <div className={style.exampleItem}>
            <div className={style.exampleTitle}>{title}</div>
            <div className={style.exampleTextContainer}>{text}</div>
        </div>
    );
}

function createJsonExample(title: string, json: Record<string, unknown>) {
    return (
        <div className={style.exampleItem}>
            <div className={style.exampleTitle}>{title}</div>
            <div className={style.exampleJsonContainer}>
                <ReactJson
                    collapsed={false}
                    // Can't set displayArrayKey to false at this time.
                    // See https://github.com/mac-s-g/react-json-view/issues/380.
                    displayDataTypes={false}
                    displayObjectSize={false}
                    indentWidth={4}
                    name={false}
                    sortKeys={true}
                    src={json}
                />
            </div>
        </div>
    );
}

function createBodyExamples(t: TFunction, endpoint: Endpoint): JSX.Element {
    return (
        <div className={style.exampleSection}>
            {endpoint.requestExample && (
                <div className={style.textWidth}>
                    {createJsonExample(t("Support.apiGuide.requestExample"), endpoint.requestExample)}
                </div>
            )}
            {endpoint.responseExample}
        </div>
    );
}

function createCollapsibleSection(showText: string, hideText: string, body?: JSX.Element): JSX.Element | undefined {
    if (body == null) {
        return;
    }
    const [visible, setVisible] = React.useState(false);
    const visibilityText = visible ? hideText : showText;
    const effectiveBody = visible ? body : null;
    return (
        <div className={style.textWidth}>
            <a onClick={() => setVisible((current) => !current)}>{visibilityText}</a>
            {effectiveBody}
        </div>
    );
}

const minifyJson = (postBody: string) => {
    const withoutQuotes = postBody.trim().replace(/^'/, "").replace(/'$/, "");
    return singleQuote(JSON.stringify(JSON.parse(withoutQuotes)));
};

const indentJson = (value: string) => {
    return value
        .split("\n")
        .map((line) => INDENT + line)
        .join("\n");
};

function createCurlSection(t: TFunction, commandParts: CommandPart[]): JSX.Element {
    const copyText = t("Common.copyToClipboard");
    const [copyTooltipText, setCopyTooltipText] = React.useState<string>(copyText);

    const copiedCommand = commandParts
        .map((part) => (part.type === CommandPartType.JSON ? minifyJson(part.value) : part.value))
        .join(" ");

    function clickHandler() {
        navigator.clipboard.writeText(copiedCommand);
        setCopyTooltipText(t("Common.copied"));
        setTimeout(() => {
            setCopyTooltipText(copyText);
        }, TOOLTIP_TIMEOUT);
    }

    const displayedCommand = commandParts
        .map((part) => {
            return part.indent
                ? part.type === CommandPartType.PLAINTEXT
                    ? INDENT + part.value
                    : indentJson(part.value)
                : part.value;
        })
        .join("\n");
    return (
        <div className={style.curlExample}>
            <textarea readOnly={true} wrap={"hard"} value={displayedCommand} data-resize={"true"}></textarea>
            <Tooltip content={copyTooltipText} maxWidth={400}>
                <span onClick={clickHandler} className={style.curlExampleCopyIcon}>
                    <ApiPlainCopy />
                </span>
            </Tooltip>
        </div>
    );
}

function createParameterSection(
    t: TFunction,
    urlParameters: Array<RequestParameter> = [],
    bodyParameters: Array<RequestParameter> = []
): JSX.Element | undefined {
    const missingUrls = urlParameters.length == 0;
    const missingBodies = bodyParameters.length == 0;
    if (missingUrls && missingBodies) {
        return;
    }

    const headers = [
        { className: style.parameterColumn, value: t("Support.apiGuide.parameterTable.header.parameter") },
        { className: style.requiredColumn, value: t("Support.apiGuide.parameterTable.header.required") },
        { value: t("Support.apiGuide.parameterTable.header.description") },
    ];

    const cellMapper = (each: RequestParameter) => [
        each.name,
        each.required ? <img src={checkMark} alt={t("AltText.checkmark")} /> : null,
        each.description,
    ];

    const urlPart = !missingUrls && (
        <div>
            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("Support.apiGuide.urlRequestParameters")}
            </Heading>
            <StaticTable headers={headers} cells={urlParameters.map(cellMapper)} />
        </div>
    );
    const bodyPart = !missingBodies && (
        <div>
            <Heading tag={"div"} variant="SUBTITLE_1">
                {t("Support.apiGuide.bodyRequestParameters")}
            </Heading>
            <StaticTable headers={headers} cells={bodyParameters.map(cellMapper)} />
        </div>
    );
    return (
        <div>
            {urlPart}
            {bodyPart}
        </div>
    );
}

function createExportReportWithUuidEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const languageListing = LANGUAGES.map((each) => `"${each}"`).join(", ");
    return {
        linkId: "export-single-report",
        title: t("Support.apiGuide.endpoint.exportSingleReport.title"),
        introduction: t("Support.apiGuide.endpoint.exportSingleReport.introduction"),
        url: baseUrl,
        method: "GET",
        format: t("Common.na"),
        responseExample: createTextExample(
            t("Support.apiGuide.response"),
            t("Support.apiGuide.endpoint.exportSingleReport.responseDescription")
        ),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}/4715f169-1229-4315-90b4-c125a031e865`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "uuid",
                required: true,
                description: t("Support.apiGuide.endpoint.exportSingleReport.uuidParameter"),
            },
            {
                name: "category",
                required: false,
                description: t("Support.apiGuide.endpoint.exportSingleReport.categoryParameter"),
            },
            {
                name: "format",
                required: false,
                description: t("Support.apiGuide.endpoint.exportSingleReport.formatParameter"),
            },
            {
                name: "language",
                required: false,
                description: t("Support.apiGuide.endpoint.exportSingleReport.languageParameter", {
                    languages: languageListing,
                }),
            },
            {
                name: "template_uuid",
                required: false,
                description: t("Support.apiGuide.endpoint.exportSingleReport.templateUuidParameter"),
            },
        ]),
        description: (
            <>
                <Heading tag="div" variant="SUBTITLE_1">
                    {t("Support.apiGuide.paths.title")}
                </Heading>
                <TextBlock>{t("Support.apiGuide.paths.description")}</TextBlock>
            </>
        ),
    };
}

function createExportReportGetEndpoint(t: TFunction, baseUrl: string, cursor: RequestParameter): Endpoint {
    // without IMEI filtering. Add them if there's time.
    return {
        linkId: "export-reports-get",
        title: t("Support.apiGuide.endpoint.exportReportsGet.title"),
        introduction: t("Support.apiGuide.endpoint.exportReportsGet.introduction"),
        url: baseUrl,
        method: "GET",
        format: t("Common.na"),
        responseExample: createTextExample(t("Support.apiGuide.response"), t("Support.apiGuide.zipResponse")),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}?@imei=355324081989399`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "@imei",
                required: false,
                description: t("Support.apiGuide.endpoint.exportReportsGet.imeiFilterDescription"),
            },
            {
                name: "category",
                required: false,
                description: t("Support.apiGuide.endpoint.exportSingleReport.categoryParameter"),
            },
            cursor,
        ]),
    };
}

const singleQuote = (value: string) => {
    return "'" + value + "'";
};

function createExportReportPostEndpoint(t: TFunction, baseUrl: string, cursor: RequestParameter): Endpoint {
    const postCurlExample = {
        filter: { date: { gt: "2021-12-31T23:59:59Z" }, fields: [{ like: "355324081989399", name: "@imei" }] },
    };
    const filterParameters = [
        {
            name: "category",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.category"),
        },
        {
            name: "format",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.format"),
        },
        {
            name: "container",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.container"),
        },
        {
            name: "filter",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.filter"),
        },
        {
            name: "filter.date",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.date"),
        },
        {
            name: "filter.fields",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.fields"),
        },
        {
            name: "filter.options.logic",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.logic"),
        },
        {
            name: "csv.columns",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.columns", {
                columnCountMin: MIN_CSV_COLUMN_COUNT,
                columnCountMax: MAX_COLUMN_COUNT,
                exampleColumns: `[${CSV_EXAMPLE_COLUMNS}]`,
            }),
        },
        {
            name: "size",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.size", {
                reportCountMin: MIN_REPORT_COUNT,
                reportCountMax: MAX_REPORT_COUNT,
                reportCountDefault: DEFAULT_REPORT_COUNT,
            }),
        },
        {
            name: "sort_by",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.sortBy"),
        },
        {
            name: "sort_direction",
            required: false,
            description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.sortDirection"),
        },
    ];
    return {
        linkId: "export-reports-post",
        title: t("Support.apiGuide.endpoint.exportReportsPost.title"),
        introduction: t("Support.apiGuide.endpoint.exportReportsPost.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        requestExample: {
            cursor: "",
            filter: {
                category: "ERASURE",
                date: { gt: "2021-12-31T23:59:59-0500" },
                fields: [{ name: "@imei", like: "355324081989399" }],
            },
        },
        responseExample: createTextExample(t("Support.apiGuide.response"), t("Support.apiGuide.zipResponse")),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(postCurlExample, null, 4))),
            createPlainTextCommandPart(baseUrl),
        ]),
        parameterSection: createParameterSection(t, [cursor], [cursor, ...filterParameters]),
    };
}

function createCompareReportsEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const requestExample = {
        compared_paths: ["blancco_hardware_report.disks.disk.serial"],
        match_path: "blancco_hardware_report.system.serial",
    };
    const newReportUuid = "cdf8013d-d831-4497-813d-a4ef142da405";
    return {
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(requestExample, null, 4))),
            createPlainTextCommandPart(baseUrl.replace("{uuid}", newReportUuid)),
        ]),
        format: "application/json",
        introduction: t("Support.apiGuide.endpoint.compareReportsPost.introduction"),
        linkId: "compare-reports-post",
        method: "POST",
        requestExample: requestExample,
        responseExample: createJsonExample(t("Support.apiGuide.response"), {
            comparison: {
                success: { code: "OK" },
                report_uuids: [newReportUuid, "f64303a8-de32-4b20-857e-c4abbb2497d4"],
                mismatch: {
                    found: true,
                    mismatches: [
                        { path: "blancco_hardware_report.disks.disk.serial", old_value: "WD-313", new_value: "WD-131" },
                    ],
                },
            },
        }),
        title: t("Support.apiGuide.endpoint.compareReportsPost.title"),
        url: baseUrl,
        parameterSection: createParameterSection(
            t,
            [
                {
                    name: "uuid",
                    required: true,
                    description: t("Support.apiGuide.endpoint.compareReportsPost.parameterDescription.uuid"),
                },
            ],
            [
                {
                    name: "compared_paths",
                    required: true,
                    description: t("Support.apiGuide.endpoint.compareReportsPost.parameterDescription.comparedPaths"),
                },
                {
                    name: "match_path",
                    required: true,
                    description: t("Support.apiGuide.endpoint.compareReportsPost.parameterDescription.matchPath"),
                },
                {
                    name: "email_notification",
                    required: false,
                    description: t(
                        "Support.apiGuide.endpoint.compareReportsPost.parameterDescription.emailNotification"
                    ),
                },
            ]
        ),
    };
}

function createUserCreationPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const requestExample = {
        name: "Jane Doe",
        email: "jane.doe@example.com",
    };
    const responseExample = {
        user: {
            uuid: "28f372a8-58e9-4468-adec-2623707d289c",
        },
    };
    const parameters = ["name", "email"].map((each) => ({
        name: each,
        required: each != "name",
        description: t("Support.apiGuide.endpoint.createUsersPost.parameterDescription." + each),
    }));

    return {
        linkId: "create-users-post",
        title: t("Support.apiGuide.endpoint.createUsersPost.title"),
        introduction: t("Support.apiGuide.endpoint.createUsersPost.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        requestExample: requestExample,
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(requestExample, null, 4))),
            createPlainTextCommandPart(baseUrl),
        ]),
        parameterSection: createParameterSection(t, [], [...parameters]),
    };
}

function createTenantCreationPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const requestExample = {
        name: "Jane Doe",
        contact_email: "jane.doe@example.com",
        contact_name: "Doe Jane",
        salesforce_id: "12345",
        type: "CUSTOMER",
        region: "eu-west-1",
        tier: "BASIC",
        licensing_model: "OLD_MODEL",
        country_code: "DZ",
        notes: "",
        ems: {
            ems_id: null,
            hl_entitlements: false,
            sl_entitlements: false,
            available_sl_activations: 0,
            used_sl_activations: 0,
            last_synced: "",
        },
        license_delivery: {
            type: "NEW_DEAL",
            case_number: "1234",
            opportunity_id: "1234",
            notes: "",
            licenses: [
                {
                    license_id: "70",
                    amount: 2,
                    expiration_date: "2091-01-01T21:59:59Z",
                },
            ],
        },
        expiration_date: "",
    };
    const responseExample = {
        tenant: {
            name: "John Doe",
            salesforce_id: "12345",
            tenant_uuid: "cd5030a7-95ec-45b2-9b2f-bae71f8f41da",
        },
    };
    const queryParameters: RequestParameter[] = [
        {
            name: "tenant_uuid",
            required: false,
            description: t("Support.apiGuide.endpoint.createTenantPost.parameterDescription"),
        },
    ];

    return {
        linkId: "create-tenants-post",
        title: t("Support.apiGuide.endpoint.createTenantPost.title"),
        introduction: t("Support.apiGuide.endpoint.createTenantPost.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        requestExample: requestExample,
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(requestExample, null, 4))),
            createPlainTextCommandPart(baseUrl),
        ]),
        parameterSection: createParameterSection(t, [], [...queryParameters]),
    };
}

function createFetchTenantPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const responseExample = {
        tenant: {
            name: "some tenant",
            uuid: "some-uuid",
            total_reports: 12,
            salesforce_id: 1234,
            licensing_model: "some model",
            status: true,
            tier: "some tier",
            created_timestamp: "2024-03-15 10:14:04.984601",
            expiration_date: "",
            type: "tenant type",
            contact: {
                username: "contact@customer.com",
                active: true,
            },
        },
    };
    return {
        linkId: "fetch-tenant",
        title: t("Support.apiGuide.endpoint.fetchTenant.title"),
        introduction: t("Support.apiGuide.endpoint.fetchTenant.introduction"),
        url: baseUrl,
        method: "GET",
        format: t("application/json"),
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}/4715f169-1229-4315-90b4-c125a031e865`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "uuid",
                required: true,
                description: t("Support.apiGuide.endpoint.fetchTenant.uuidParameter"),
            },
        ]),
    };
}

function createFetchTenantsLicensesPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const responseExample = {
        licenses: [
            {
                product: "1",
                balance: 123,
                expiration_date: "2091-01-01T00:00:00Z",
            },
        ],
        cursor: null,
    };

    return {
        linkId: "tenants-licenses-get",
        title: t("Support.apiGuide.endpoint.fetchTenantsLicenses.title"),
        introduction: t("Support.apiGuide.endpoint.fetchTenantsLicenses.introduction"),
        url: baseUrl,
        method: "GET",
        format: "application/json",
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}?tenant_uuid=99793e43-ea44-4295-9edd-6cd95ee75a3e`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "tenant_uuid",
                required: false,
                description: t("Support.apiGuide.endpoint.fetchTenantsLicenses.tenantUuidParameter"),
            },
        ]),
    };
}
function createLicenseDeliveryPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const requestExample = {
        type: "NEW_DEAL",
        case_number: "1234",
        opportunity_id: "1234",
        notes: "testing public api",
        licenses: [
            {
                license_id: "license_id",
                amount: 1,
                expiration_date: "YYYY-mm-ddTHH:MM:SSZ",
            },
        ],
    };
    const parameters = [
        "type",
        "opportunity_id",
        "case_number",
        "notes",
        "licenses",
        "license_id",
        "amount",
        "expiration_date",
    ].map((each) => ({
        name: each,
        required: each != "name",
        description: t("Support.apiGuide.endpoint.createLicenseDeliveryPost.parameterDescription." + each),
    }));

    return {
        linkId: "create-license-delivery-post",
        title: t("Support.apiGuide.endpoint.createLicenseDeliveryPost.title"),
        introduction: t("Support.apiGuide.endpoint.createLicenseDeliveryPost.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        requestExample: requestExample,
        responseExample: <></>,
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(COMMAND_PART_XPOST),
            createPlainTextCommandPart(CONTENT_TYPE_JSON_HEADER),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(COMMAND_PART_DATA),
            createJsonCommandPart(singleQuote(JSON.stringify(requestExample, null, 4))),
            createPlainTextCommandPart(baseUrl),
        ]),
        parameterSection: createParameterSection(t, [], [...parameters]),
    };
}

function createFetchTenantsPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const responseExample = {
        tenants: [
            {
                name: "some tenant",
                uuid: "some-uuid",
                total_reports_count: 12,
                salesforce_id: 1234,
                licensing_model: "some model",
                status: true,
                tier: "some tier",
                created_timestamp: "2024-03-15 10:14:04.984601",
                expiration_date: "",
                type: "tenant type",
                parent_uuid: "parent-uuid",
                subtenants_count: 12,
                contact: {
                    username: "contact@customer.com",
                    active: true,
                },
                licenses: [
                    {
                        product_id: "12",
                        product: "product name",
                        assigned: "23",
                        balance: 0,
                        expiration_date: "",
                        consumed: 0,
                    },
                ],
            },
        ],
        cursor: null,
    };
    const queryParameters: RequestParameter[] = [
        {
            name: "salesforce_id",
            required: false,
            description: "Salesforce ID to filter tenants.",
        },
        {
            name: "parent_uuid",
            required: false,
            description: "Parent tenant to filter tenants.",
        },
        {
            name: "licenses_available",
            required: false,
            description: "Flag to list tenant's licenses.",
        },
        {
            name: "license_usage",
            required: false,
            description:
                "Flag to show the amount of licenses for each product consumed in the last 30 days or since the date given in the request parameters.",
        },
        {
            name: "date",
            required: false,
            description:
                "Filter tenants with the latest license change since this date. It is also used to compute the license consumption since this date.",
        },
        {
            name: "page_size",
            required: false,
            description:
                "The number of tenants returned per request should be adjusted (higher or lower) to prevent the API from becoming unavailable, due to timing out, in case the response is too large.",
        },
        {
            name: "until_date",
            required: false,
            description:
                "Used with 'date' parameter, to filter tenants with the latest license change until this date. It is also used to compute the license consumption until this date.",
        },
    ];
    return {
        linkId: "fetch-tenants",
        title: t("Support.apiGuide.endpoint.fetchTenantsApi.title"),
        introduction: t("Support.apiGuide.endpoint.fetchTenantsApi.description"),
        url: baseUrl,
        method: "GET",
        format: t("application/json"),
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}`),
        ]),
        parameterSection: createParameterSection(t, queryParameters, []),
    };
}

function createFetchTenantsEsgDataPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const responseExample = {
        sustainability_data: {
            months: [
                {
                    month: "2023-04",
                    content: {
                        laptop: {
                            weight: 0,
                            co2: 0,
                        },
                        desktop: {
                            weight: 0,
                            co2: 0,
                        },
                        server: {
                            weight: 0,
                            co2: 0,
                        },
                        loose_drive: {
                            weight: 0,
                            co2: 0,
                        },
                        smartphone: {
                            weight: 0,
                            co2: 0,
                        },
                        tablet: {
                            weight: 0,
                            co2: 0,
                        },
                    },
                },
            ],
            cursor: null,
        },
    };

    return {
        linkId: "tenants-esg-data-get",
        title: t("Support.apiGuide.endpoint.fetchTenantsEsgData.title"),
        introduction: t("Support.apiGuide.endpoint.fetchTenantsEsgData.introduction"),
        url: baseUrl,
        method: "GET",
        format: "application/json",
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}?tenant_uuid=99793e43-ea44-4295-9edd-6cd95ee75a3e`),
        ]),
        parameterSection: createParameterSection(t, [
            {
                name: "tenant_uuid",
                required: false,
                description: t("Support.apiGuide.endpoint.fetchTenantsEsgData.tenantUuidParameter"),
            },
        ]),
    };
}

function createFetchTenantsLicenseEventsPublicApiEndpoint(t: TFunction, baseUrl: string): Endpoint {
    const responseExample = {
        events: [
            {
                product: "1",
                amount_before: 123,
                amount_after: 124,
                expiration_before: "2023-01-01T00:00:00Z",
                expiration_after: "2023-01-01T00:00:00Z",
                event_type: "DELIVERY",
            },
            {
                product: "2",
                amount_before: 3,
                amount_after: 2,
                expiration_before: "2023-01-01T00:00:00Z",
                expiration_after: "2023-01-01T00:00:00Z",
                event_type: "CONSUMPTION",
            },
        ],
    };
    const filterParameters = [
        {
            name: "start_date",
            required: false,
            description: t("Support.apiGuide.endpoint.fetchTenantsLicenseEventsData.parameterDescription.startDate"),
        },
        {
            name: "end_date",
            required: false,
            description: t("Support.apiGuide.endpoint.fetchTenantsLicenseEventsData.parameterDescription.endDate"),
        },
    ];
    return {
        linkId: "tenants-license-events-get",
        title: t("Support.apiGuide.endpoint.fetchTenantsLicenseEventsData.title"),
        introduction: t("Support.apiGuide.endpoint.fetchTenantsLicenseEventsData.introduction"),
        url: baseUrl,
        method: "POST",
        format: "application/json",
        responseExample: createJsonExample(t("Support.apiGuide.response"), responseExample),
        curlSection: createCurlSection(t, [
            createPlainTextCommandPart(COMMAND_PART_CURL, false),
            createPlainTextCommandPart(API_KEY_HEADER),
            createPlainTextCommandPart(`${baseUrl}?tenant_uuid=99793e43-ea44-4295-9edd-6cd95ee75a3e`),
        ]),
        parameterSection: createParameterSection(
            t,
            [
                {
                    name: "tenant_uuid",
                    required: true,
                    description: t("Support.apiGuide.endpoint.fetchTenantsLicenseEventsData.tenantUuidParameter"),
                },
            ],
            [...filterParameters]
        ),
    };
}

export default function EndpointsView(): JSX.Element {
    const { t } = useTranslation();
    const reportExportUrl = getPublicApiUrl() + "/reports/export";
    const reportCompareUrl = getPublicApiUrl() + "/reports/{uuid}/compare";
    const singleReportCompareUrl = getPublicApiUrl() + "/reports/{uuid}/export";
    const userCreationUrl = getPublicApiUrl() + "/users";
    const licenseDeliveryUrl = getPublicApiUrl() + "/license-deliveries";
    const tenantManagementBaseUrl = getPublicApiUrl() + "/tenants";
    const fetchTenantUrl = tenantManagementBaseUrl + "/{uuid}";
    const fetchTenantsLicensesUrl = getPublicApiUrl() + "/licenses";
    const fetchTenantsEsgDataUrl = getPublicApiUrl() + "/sustainabilities";
    const fetchTenantsLicenseEvents = getPublicApiUrl() + "/license-events";

    const cursorParameter = {
        name: "cursor",
        required: false,
        description: t("Support.apiGuide.endpoint.exportReportsPost.parameterDescription.cursor"),
    };
    const endpoints = [
        createExportReportWithUuidEndpoint(t, singleReportCompareUrl),
        createExportReportGetEndpoint(t, reportExportUrl, cursorParameter),
        createExportReportPostEndpoint(t, reportExportUrl, cursorParameter),
        createUserCreationPublicApiEndpoint(t, userCreationUrl),
    ];
    const compareReportsEndpoint = createCompareReportsEndpoint(t, reportCompareUrl);
    if (useFeature(FLAG_COMPARE_REPORTS)) {
        // Add to be located after the last report endpoint and before create-user endpoint.
        endpoints.splice(3, 0, compareReportsEndpoint);
    }
    endpoints.push(createFetchTenantsPublicApiEndpoint(t, tenantManagementBaseUrl));
    endpoints.push(createFetchTenantPublicApiEndpoint(t, fetchTenantUrl));
    endpoints.push(createTenantCreationPublicApiEndpoint(t, tenantManagementBaseUrl));
    endpoints.push(createFetchTenantsLicensesPublicApiEndpoint(t, fetchTenantsLicensesUrl));
    endpoints.push(createLicenseDeliveryPublicApiEndpoint(t, licenseDeliveryUrl));
    endpoints.push(createFetchTenantsEsgDataPublicApiEndpoint(t, fetchTenantsEsgDataUrl));
    endpoints.push(createFetchTenantsLicenseEventsPublicApiEndpoint(t, fetchTenantsLicenseEvents));
    const links = endpoints.map((each) => (
        <div key={each.linkId}>
            <a
                href={
                    window.location.href.includes("#")
                        ? window.location.href.split("#")[0] + "#" + each.linkId
                        : window.location.href + "#" + each.linkId
                }
            >
                {each.title}
            </a>
        </div>
    ));
    const endpointSections = endpoints.map((each) => (
        <div key={each.linkId} id={each.linkId} className={style.endpointSections}>
            <div className={endpointStyle.textWidth}>{each.description}</div>
            <Heading tag="div" variant="SUBTITLE_1">
                {each.title}
            </Heading>
            <div className={endpointStyle.textWidth}>
                <TextBlock>{each.introduction}</TextBlock>
            </div>
            {!each.requestExample && (
                <div className={style.gridColumnDisplay}>
                    {createUrlSection(t, each)}
                    {createBodyExamples(t, each)}
                </div>
            )}
            {each.requestExample && (
                <>
                    <div className={style.textWidth}>{createUrlSection(t, each)}</div>
                    {createBodyExamples(t, each)}
                </>
            )}

            {each.details && <div className={style.textWidth}>{each.details}</div>}
            {createCollapsibleSection(t("Support.apiGuide.showCurl"), t("Support.apiGuide.hideCurl"), each.curlSection)}
            {createCollapsibleSection(
                t("Support.apiGuide.showParameters"),
                t("Support.apiGuide.hideParameters"),
                each.parameterSection
            )}
        </div>
    ));
    React.useEffect(() => {
        // It's really difficult to place the copy icon to the upper right corner of the textarea while also preparing
        // for possible scrollbars. Here the issue is solved by eliminating the scrollbars completely and always
        // resizing the textarea to fit the content. Given that the textareas aren't always visible when the page loads,
        // we need to do this in useEffect.
        const areas = document.querySelectorAll('textarea[data-resize="true"]');
        for (let i = 0; i < areas.length; i++) {
            areas[i].setAttribute("style", "height:" + areas[i].scrollHeight + "px");
        }
    });
    return (
        <div>
            <Heading tag="div" variant="SUBTITLE_1">
                {t("Support.apiGuide.availableEndpoints")}
            </Heading>
            <div>{links}</div>
            <div>{endpointSections}</div>
        </div>
    );
}
