// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!lato-font/css/lato-font.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "._2ZSks8s9A1p3uJY6bNd-LH{width:100%}._2ZSks8s9A1p3uJY6bNd-LH ._2qJScJ42cOnKvhRx0kvGXO{background-color:#385cad;max-height:100px}._2ZSks8s9A1p3uJY6bNd-LH ._2qJScJ42cOnKvhRx0kvGXO ._3ZOD8neYfgC5TkFFJRKycW{display:inline-block;margin:20px}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l{margin:auto;width:80%;text-align:center}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l ._2xeX7JMF-U2hN2_4nq3bB2{margin:30px 0}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l .AOi__APyHYotqZzoJ56ws{width:max-content;background-color:#f4f4f4;margin:0 auto;padding:20px;border-radius:5px}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l .AOi__APyHYotqZzoJ56ws ._1F8NPR935Qw5rs-dNj7R7Z{padding:20px 20px 0 20px}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l ._3vXDxlNsWQ7_LlWZJkUaZZ{margin-top:20px}._2ZSks8s9A1p3uJY6bNd-LH ._1MmSuBx06poSR5NhYhQ9-l ._3vXDxlNsWQ7_LlWZJkUaZZ button{width:100%;max-width:380px;margin:0;font-size:14px;font-weight:600;line-height:20px;text-transform:uppercase}._2ZSks8s9A1p3uJY6bNd-LH ._11zD28aEqE1RCoUqZoRVLR{font-family:\"Lato Medium\",Arial,Helvetica,sans-serif;font-size:12px;line-height:18px;position:absolute;bottom:0;text-align:center;width:100%;height:40px;color:#bababa}._1CKFT_mnrc7Gae8sszrHgh{width:100%;text-align:center;margin-top:40px;padding:0 20px}._1CKFT_mnrc7Gae8sszrHgh ._2xeX7JMF-U2hN2_4nq3bB2{margin-top:20px;font-size:22px;font-family:\"Roboto Medium\";line-height:30px}._1CKFT_mnrc7Gae8sszrHgh ._12g28dF3kqXowvQ2tBxV3{margin-top:20px;font-size:17px;line-height:20px;font-family:\"Roboto\"}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"whiteColor": "#fff",
	"activeBackgroundColor": "#476c86",
	"activeForegroundColor": "#476c86",
	"primaryButtonBackgroundColor": "#476c86",
	"secondaryButtonForegroundColor": "#91b9f0",
	"bannerBackgroundColor": "rgba(71,108,134,.5)",
	"linkTextColor": "#5f96bc",
	"primaryButtonBackgroundHoverColor": "#5f96bc",
	"secondaryButtonBackgroundHoverColor": "#354057",
	"destructiveButtonBackgroundHoverColor": "#c12020",
	"destructiveButtonActiveBackgroundColor": "#db2828",
	"primaryButtonBackgroundActiveColor": "#222",
	"secondaryButtonBackgroundActiveColor": "#273044",
	"destructiveButtonBackgroundColor": "#a51313",
	"chartNegativeColor": "#f09191",
	"chartPositiveColor": "#5fe3a1",
	"chartNeutralColor": "#cecece",
	"errorBackgroundColor": "#f46464",
	"errorBorderColor": "#f46464",
	"errorTextColor": "#f46464",
	"errorWrapperBackgroundColor": "#f2c5c5",
	"warningBackgroundColor": "#e47530",
	"warningBorderColor": "#e47530",
	"warningTextColor": "#e47530",
	"secondaryWarningBackgroundColor": "#ffc915",
	"secondaryWarningBorderColor": "#ffc915",
	"secondaryWarningTextColor": "#ffc915",
	"successBorderColor": "#5fe3a1",
	"successTextColor": "#5fe3a1",
	"pageBackgroundColor": "#222",
	"wrapperBackgroundColor": "#dfe4f2",
	"wrapperBorderColor": "#a1b0d5",
	"modelBoxBackgroundColor": "#4c4c4c",
	"contentBackgroundColor": "#333",
	"secondaryButtonBackgroundColor": "#333",
	"textColor": "#fff",
	"primaryButtonForegroundColor": "#fff",
	"bannerForegroundColor": "#4c4c4c",
	"chartOutlineColor": "#222",
	"modalOverlayColor": "rgba(34,34,34,.4)",
	"contentDividerColor": "#111",
	"contentBorderColor": "#111",
	"nestedContentBorderColor": "#cecece",
	"disabledButtonBackgroundColor": "#cecece",
	"disabledButtonForegroundColor": "#4c4c4c",
	"inputHintColor": "#cecece",
	"inputBackgroundColor": "#4c4c4c",
	"hoverBackgroundColor": "#111",
	"toggleCheckedBackgroundColor": "#91b9f0",
	"toggleUncheckedBackgroundColor": "#cecece",
	"toggleForegroundColor": "#fff",
	"mapForegroundColor": "#f0f0f0",
	"mapBackgroundColor": "#222",
	"emptyStateEllipseBackgroundColor": "#494949",
	"emptyStateBiggestCircleBackgroundColor": "#484848",
	"emptyStateSmallestCircleBackgroundColor": "#4b4b4b",
	"emptyStatePersonColor": "#4a4a52",
	"emptyStatePersonShirtColor": "#91b9f0",
	"emptyStatePersonSleeveColor": "#3b5dab",
	"emptyStateEllipseBackgroundOpacity": ".599",
	"emptyStateBiggestCircleBackgroundOpacity": ".197",
	"emptyStateSmallestCircleBackgroundOpacityFirst": ".401",
	"emptyStateSmallestCircleBackgroundOpacitySecond": ".602",
	"emptyStateSmallestCircleBackgroundOpacityThird": ".846",
	"chartFirstSet": "#708add",
	"chartSecondSet": "#dd75a0",
	"chartThirdSet": "#4a7ab6",
	"chartForthSet": "#5f96bc",
	"chartFifthSet": "#c4874a",
	"chartSixthSet": "#b63e5c",
	"chartSeventhSet": "#6c8fa9",
	"chartEighthSet": "#f3a14e",
	"chartNinthSet": "#88bbdb",
	"chartTenthSet": "#e2afc3",
	"chartEleventhSet": "#b1bfde",
	"chartTwelfthSet": "#facf96",
	"optional": "#fff",
	"disabledTextColor": "#4c4c4c",
	"colorPickerBlack": "#000",
	"iconFillColor": "#91b9f0",
	"sortIconRegularColor": "#cecece",
	"sortIconHoverColor": "#5f96bc",
	"sortIconActiveColor": "#fff",
	"calendarRangeBackgroundColor": "#91b9f0",
	"selectedTabColor": "#91b9f0",
	"basicTierBannerColor": "rgba(188,132,74,.0980392157)",
	"basicTierBannerBorderColor": "#9d6f3f",
	"premiumTierBannerColor": "rgba(107,149,184,.0980392157)",
	"premiumTierBannerBorderColor": "#6b95b8",
	"diamondTierBannerColor": "rgba(181,78,119,.0980392157)",
	"diamondTierBannerBorderColor": "#b54e77",
	"starTierBannerBorderColor": "#6f53e0",
	"chartSuccessColor": "#009e57",
	"chartFailColor": "#db3737",
	"notVerified": "#708add",
	"textFieldColor": "#111",
	"focusOutlineColorInput": "#3b5dab",
	"focusOutlineColorButton": "#d661e5",
	"tooltipTextColor": "#4c4c4c",
	"tooltipBackgroundColor": "#f0f0f0",
	"chipBackgroundColor": "#4c4c4c",
	"chipContentColor": "#fff",
	"chipUserIconColor": "#111",
	"successIconColor": "#5fe3a1",
	"errorIconColor": "#f09191",
	"localBannerBackgroundColor": "#33405f",
	"localBannerBorderColor": "#2d2d2d",
	"errorBannerBackgroundColor": "#fdf4f4",
	"localBannerTextColor": "#fff",
	"successBadgeBackgroundColor": "#006f1f",
	"successBadgeTextColor": "#e5f2e9",
	"warningBadgeBackgroundColor": "#ffda5c",
	"warningBadgeTextColor": "#715907",
	"errorBadgeBackgroundColor": "#9c2a2a",
	"errorBadgeTextColor": "#fff",
	"infoBadgeBackgroundColor": "#374f86",
	"infoBadgeTextColor": "#fff",
	"infoIconBackgroundColor": "#f0f0f0",
	"neutralBadgeBackgroundColor": "#606060",
	"neutralBadgeTextColor": "#fff",
	"strokeColor": "#364c7d",
	"textHighlightForegroundColor": "linear-gradient(0deg, rgba(255, 201, 21, 0.3) 0%, rgba(255, 201, 21, 0.3) 100%)",
	"textHighlightBackgroundColor": "#fff",
	"skeletonLoaderBackgroundColor": "#222",
	"firstLevelTierBackgroundColor": "#fff6ee",
	"secondLevelTierBackgroundColor": "#f2faff",
	"thirdLevelTierBackgroundColor": "#fff8fb",
	"fourthLevelTierBackgroundColor": "#6f53e0",
	"defaultStarColor": "#007b22",
	"userDefaultStarColor": "#cecece",
	"addOnsCheckmarkColor": "#5fe3a1",
	"warningBannerBackgroundColor": "#ffd95b",
	"warningBannerBorderColor": "#fff1c1",
	"warningBannerTextColor": "#111",
	"warningIconBackgroundColor": "#111",
	"warningIconExclamationColor": "#ffc915",
	"hoverColor": "rgba(71,108,134,.3)",
	"conditionToggleButtonBackgroundColor": "#222",
	"conditionToggleButtonBorderColor": "#4c4c4c",
	"conditionTableBackgroundColor": "#222",
	"conditionTableBorderColor": "#4c4c4c",
	"conditionTableNestedBackgroundColor": "#111",
	"tabHoverColor": "#4c4c4c",
	"modularPurpleColor": "#b9a8ff",
	"modularBackgroundPurpleColor": "#41366e",
	"readyToUseSkyBlueColor": "#72bcf8",
	"readyToUseBackgroundBlueColor": "#144a76",
	"readyToUseBorderBlueColor": "#0c62a8",
	"modularBorderPurpleColor": "#53429c",
	"descriptionTextColor": "#8e8e8e",
	"lightGreenBackgroundColor": "#e5f2e9",
	"lightBlueBackgroundColor": "#e0e5f3",
	"defaultTheme": "defaultTheme",
	"tenantTheme": "tenantTheme",
	"defaultDarkTheme": "defaultDarkTheme",
	"tenantDarkTheme": "tenantDarkTheme",
	"subTenantTheme": "subTenantTheme",
	"subTenantDarkTheme": "subTenantDarkTheme",
	"downloadPage": "_2ZSks8s9A1p3uJY6bNd-LH",
	"header": "_2qJScJ42cOnKvhRx0kvGXO",
	"logo": "_3ZOD8neYfgC5TkFFJRKycW",
	"container": "_1MmSuBx06poSR5NhYhQ9-l",
	"title": "_2xeX7JMF-U2hN2_4nq3bB2",
	"appIcon": "AOi__APyHYotqZzoJ56ws",
	"appTitle": "_1F8NPR935Qw5rs-dNj7R7Z",
	"buttonContainer": "_3vXDxlNsWQ7_LlWZJkUaZZ",
	"footer": "_11zD28aEqE1RCoUqZoRVLR",
	"error": "_1CKFT_mnrc7Gae8sszrHgh",
	"message": "_12g28dF3kqXowvQ2tBxV3"
};
module.exports = exports;
