import { DownloadPageConfiguration } from "domain/downloadPage";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";

/**
 * Handles interactions with get download page configuration API.
 */

class DownloadPageService {
    public async fetchConfiguration(
        licenseKey: string,
        deviceType: string,
        storeName: string | null,
        custom: string | null
    ): Promise<DownloadPageConfiguration | string> {
        const headers: Record<string, string> = {
            "License-Key": licenseKey,
            "Device-Type": deviceType,
            ...(storeName && { "Store-Name": storeName }),
            ...(custom && { custom }),
        };

        return apiGatewayService.invokeApi("/client-configurations/download-app-page-configuration", "GET", null, {
            refreshSession: false,
            apiType: ApiType.GLOBAL_STAN,
            headers,
            includeCredentials: false,
        });
    }
}

export const downloadPageService = new DownloadPageService();
