import React from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import Error from "./Error";
import Page from "./Page";
import style from "./page.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import {
    DownloadPageConfiguration,
    DownloadPageConfigurationResponse,
    pageState,
    URLParameters,
} from "domain/downloadPage";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { downloadPageService } from "services/download-page/DownloadPageService";

const DownloadPageView = (): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<string | JSX.Element>("");

    const [data, setData] = React.useState<pageState>({
        configuration: {
            appIcon: "",
            headerLogo: "",
            title: "",
            androidFirebaseLink: "",
            huaweiFirebase: "",
            iosFirebaseLink: "",
        },
    });
    const { licenseKey } = useParams<URLParameters>();
    const query = new URLSearchParams(useLocation().search);
    const storeName = query.get("store_name");
    const custom = query.get("custom");
    const deviceType = isAndroid ? "Android" : isIOS ? "iOS" : "";

    const toDownloadPageConfiguration = (data: DownloadPageConfiguration): DownloadPageConfigurationResponse => {
        return {
            appIcon: data.appIcon,
            headerLogo: data.headerLogo,
            title: data.title,
            androidFirebaseLink: data.androidFirebaseLink,
            huaweiFirebase: data.huaweiFirebase,
            iosFirebaseLink: data.iOSFirebaseLink,
            licenseFileType: data.licenseFileType,
            licenseKeyFile: data.licenseKeyFile,
        };
    };

    const fetchConfiguration = async () => {
        downloadPageService
            .fetchConfiguration(licenseKey, deviceType, storeName, custom)
            .then((data: DownloadPageConfiguration) => {
                setData({ configuration: toDownloadPageConfiguration(data) });
            })
            .catch(() => {
                setError(getUnexpectedErrorMessage(licenseKey));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getUnexpectedErrorMessage = (licenseKey: string): JSX.Element => {
        const subject = encodeURI("Blancco Management Portal - Unexpected Error Report: " + licenseKey);
        const body = encodeURI(
            "Hi there\n\n" +
                "I've experienced a persistent issue with the license key: " +
                licenseKey +
                "\nWould you be able to assist?\n\n"
        );
        const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;

        return (
            <>
                <div className={style.title}>{t("DownloadPage.errorMessages.unexpectedError.title")} </div>
                <div className={style.message}>
                    {t("DownloadPage.errorMessages.unexpectedError.issuePersists")}{" "}
                    <a
                        href="https://servicedesk.blancco.com/servicedesk/customer/portal/6"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("DownloadPage.errorMessages.unexpectedError.here")}
                    </a>
                </div>
                <div className={style.message}>{t("Common.upperCaseOr")}</div>
                <div className={style.message}>
                    {t("DownloadPage.errorMessages.unexpectedError.support")} <a href={mailToLink}>{SUPPORT_EMAIL}</a>{" "}
                    {t("DownloadPage.errorMessages.unexpectedError.licenseKey")}
                </div>
                <div className={style.message}>{licenseKey}</div>
            </>
        );
    };

    React.useEffect(() => {
        if (typeof licenseKey === "undefined") {
            setError(<div className={style.title}>{t("DownloadPage.errorMessages.licenseKeyMissing")}</div>);
            return;
        }

        if (isMobile) {
            fetchConfiguration();
        } else {
            setError(<div className={style.title}>{t("DownloadPage.errorMessages.mobileDevicesOnly")}</div>);
        }
    }, []);

    return error ? (
        <Error error={error} />
    ) : loading ? (
        <LoadingIndicator />
    ) : (
        <Page licenseKey={licenseKey} storeName={storeName} custom={custom} configuration={data.configuration} />
    );
};

export default DownloadPageView;
